'use client'

import styles from './starIcon.module.css'
import cx from 'classnames'
import { StartFilledIcon } from '@/components/svg/star-filled-icon/star-filled-icon'
import { StartOutlinedIcon } from '@/components/svg/star-outlined-icon/star-outlined-icon'

export interface IStarIconProps {
  filled: boolean
  isBlack?: boolean
  inButton?: boolean
  onClick?: (e?: any) => void
}

export const V2StarIcon = ({
  filled,
  isBlack,
  inButton,
  onClick,
}: IStarIconProps) => {
  return filled ? (
    <StartFilledIcon
      className={cx(
        { [styles.starBlack]: isBlack, [styles.buttonIcon]: inButton },
        styles.icon
      )}
      onClick={onClick}
    />
  ) : (
    <StartOutlinedIcon
      className={cx(
        { [styles.starBlack]: isBlack, [styles.buttonIcon]: inButton },
        styles.icon
      )}
      onClick={onClick}
    />
  )
}
