'use client'

import qs from 'qs'
import { useEffect, useState } from 'react'
import s from './supplier-search-section.module.css'
import { LocationOption } from '@/components/v1/location-select'
import { BusinessCategory } from '@/types/api/business-category'
import { Button } from '@/components/v2/atoms/button/button'
import { V2LocationSelect } from '@/components/v2/molecules/custom-select/location-select/location-select'
import { ApiCountry } from '@/types/api/countries'
import { ROUTES } from '@/constants'
import { Tag } from '@/types/Tag'
import { SortBySelect } from '../../molecules/custom-select/sort-by-select/sort-by-select'
import { V2BusinessCategorySelect } from '../../molecules/custom-select/business-category-select/business-category-select'
import { SortSuppliersType } from '@/types/Supplier'

const SUPPLIER_SORT_OPTIONS = [
  {
    label: 'Default',
    value: '',
  },
  {
    label: 'Newest',
    value: 'newest',
  },
  {
    label: 'Oldest',
    value: 'oldest',
  },
]

type Props = {
  bcUiPlacement: number[]
  bc: BusinessCategory[]
  allBcTags?: Tag[] | null
  countries?: ApiCountry[]
  clearButton?: boolean
  showSort?: boolean
  defaultValues?: {
    location?: LocationOption
    businessCategory?: number
    businessCategoryTags?: number[]
    sort?: SortSuppliersType
  }
}

export const V2SupplierSearchSection = ({
  bcUiPlacement,
  bc,
  allBcTags,
  countries,
  clearButton,
  showSort,
  defaultValues,
}: Props) => {
  const [selectedLocation, setSelectedLocation] = useState<
    LocationOption | undefined
  >(defaultValues?.location)
  const [selectedBusinessCategory, setSelectedBusinessCategory] = useState<
    number | undefined
  >(defaultValues?.businessCategory)
  const [selectedBusinessCategoryTags, setSelectedBusinessCategoryTags] =
    useState<number[] | undefined>(defaultValues?.businessCategoryTags)
  const [sort, setSort] = useState<SortSuppliersType | undefined>(
    defaultValues?.sort
  )

  useEffect(() => {
    if (selectedBusinessCategory === defaultValues?.businessCategory) {
      setSelectedBusinessCategoryTags(defaultValues?.businessCategoryTags)
    }
  }, [
    defaultValues?.businessCategory,
    defaultValues?.businessCategoryTags,
    selectedBusinessCategory,
  ])

  const onLocationChange = (e: LocationOption) => {
    setSelectedLocation(e)
  }

  const onBusinessCategoryChange = (e: number) => {
    setSelectedBusinessCategory(e)
    // clear tags if selected business category is changed
    setSelectedBusinessCategoryTags(undefined)
  }

  const onBusinessCategoryTagChange = (e: number[]) => {
    setSelectedBusinessCategoryTags(e)
  }

  const onSortChange = (e: SortSuppliersType) => {
    setSort(e)
  }

  const stringifiedParams = qs.stringify(
    {
      ...(selectedLocation && {
        [selectedLocation?.type]: selectedLocation.value,
      }),
      ...(selectedBusinessCategoryTags && {
        tags: selectedBusinessCategoryTags,
      }),
      ...(sort && { sort }),
    },
    { arrayFormat: 'comma' }
  )

  const bcSlug = bc?.find((bc) => bc.id === selectedBusinessCategory)?.slug
  const searchUrl = `${ROUTES.WEDDING_SUPPLIERS.ROOT}${
    bcSlug ? `/${bcSlug}` : ''
  }${stringifiedParams ? `?${stringifiedParams}` : ''}`

  const handleClear = () => {
    setSelectedBusinessCategory(undefined)
    setSelectedLocation(undefined)
    setSelectedBusinessCategoryTags(undefined)
  }

  const currentBcTags =
    allBcTags && selectedBusinessCategory
      ? allBcTags?.filter(
          (bcTag) => bcTag.business_category_id === selectedBusinessCategory
        )
      : undefined

  return (
    <div className={s.wrapper}>
      <V2BusinessCategorySelect
        bcValue={selectedBusinessCategory}
        bcTagValue={selectedBusinessCategoryTags}
        bcUiPlacement={bcUiPlacement}
        bcList={bc}
        bcTagsList={currentBcTags}
        onChangeBc={onBusinessCategoryChange}
        onChangeBcTag={onBusinessCategoryTagChange}
      />
      <V2LocationSelect
        countries={countries}
        noValueButtonLabel="All Locations"
        onChange={onLocationChange}
        value={selectedLocation}
      />
      {showSort && (
        <div>
          <SortBySelect
            options={SUPPLIER_SORT_OPTIONS}
            onChange={onSortChange}
            value={sort}
          />
        </div>
      )}

      <div>
        <Button black className={s.obligatoryButton} href={searchUrl}>
          Search Suppliers
        </Button>
        {clearButton && (
          <Button href={ROUTES.WEDDING_SUPPLIERS.ROOT} onClick={handleClear}>
            Clear
          </Button>
        )}
      </div>
    </div>
  )
}
