'use client'

import { Fragment } from 'react'
import {
  Listbox,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react'
import s from './secondary-select.module.css'
import cx from 'classnames'
import { DropdownButton } from '@/components/v2/atoms/dropdown-button/dropdown-button'
import { DropdownDownIcon2 } from '@/components/svg/dropdown-down-icon-2/dropdown-down-icon-2'

type OptionItem = {
  value: number | string
  label: string
}

export interface SelectProps<T extends OptionItem> {
  label: React.ReactNode
  options: T[]
  onChange: (value: T['value']) => void
  onRemove: (value: T['value']) => void
  multiple?: boolean
  value?: T['value']
  disabled?: boolean
  className?: string
}

export const SecondarySelect = <T extends OptionItem>({
  label,
  options,
  onChange,
  onRemove,
  multiple,
  value,
  disabled,
  className,
}: SelectProps<T>) => {
  const defaultValue = multiple ? [] : null

  const onBtnLabelClick = (e: React.MouseEvent) => {
    if (value) {
      onRemove(value)
      e.stopPropagation()
    }
  }

  return (
    <div className={cx(s.wrapper, className)}>
      <Listbox
        value={value || defaultValue}
        onChange={onChange}
        multiple={multiple}
        disabled={disabled}
      >
        {({ open }) => {
          return (
            <>
              <DropdownButton
                label={<span onClick={onBtnLabelClick}>{label}</span>}
                className={s.button}
                icon={
                  <DropdownDownIcon2
                    className={cx({
                      [s.arrowUp]: open,
                    })}
                  />
                }
              />
              <Transition
                as={'div'}
                leave={s.transitionLeave}
                leaveFrom={s.opacity1}
                leaveTo={s.opacity0}
                enterFrom={s.opacity0}
                enterTo={s.opacity1}
              >
                <ListboxOptions as="div" className={s.options} static>
                  {options.map((o) => {
                    return (
                      <ListboxOption
                        as="div"
                        style={{ width: '100%' }}
                        key={o.value}
                        value={o.value}
                      >
                        {({ selected, active }) => {
                          return (
                            <div
                              className={cx(s.option, {
                                [s.selected]: selected,
                                [s.active]: active,
                              })}
                            >
                              <span>{o.label}</span>
                            </div>
                          )
                        }}
                      </ListboxOption>
                    )
                  })}
                </ListboxOptions>
              </Transition>
            </>
          )
        }}
      </Listbox>
    </div>
  )
}
