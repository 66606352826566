import { DropdownDownIcon } from '@/components/svg/dropdown-down-icon/dropdown-down-icon'
import { ListboxButton } from '@headlessui/react'
import s from './dropdown-button.module.css'
import cx from 'classnames'

type Props = {
  label: React.ReactNode
  className?: string
  icon?: React.ReactNode
} & Omit<React.HTMLAttributes<HTMLButtonElement>, 'className'>

export const DropdownButton = (props: Props) => {
  const { label, className, icon = <DropdownDownIcon /> } = props
  return (
    <ListboxButton as="button" {...props} className={cx(s.button, className)}>
      {label}
      {icon}
    </ListboxButton>
  )
}
