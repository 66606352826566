import { Select, SelectProps } from '@/components/v2/atoms/select/select'

type SortOptionItem = {
  value: string
  label: string
}

type Props = { label?: string } & Omit<SelectProps<SortOptionItem>, 'label'>

export const SortBySelect = ({ value, label, options, onChange }: Props) => {
  return (
    <Select<SortOptionItem>
      value={value}
      label={label || 'Sort by'}
      options={options}
      onChange={onChange}
    />
  )
}
