import { Article } from '../Article'
import { Image } from '../Image'
import { Supplier } from '../Supplier'
import { Tag } from '../Tag'
import { ArticleCategory, ArticleSubcategory } from './article-category'
import { BusinessCategory } from './business-category'
import { TagCategory } from './tag-category'

export enum UiPlacementType {
  TAG_CATEGORY = 'tag_category',
  TAG = 'tag',
  BUSINESS_CATEGORY = 'business_category',
  ARTICLE_CATEGORY = 'article_category',
  ARTICLE_SUBCATEGORY = 'article_subcategory',
  METADATA = 'metadata',
  SPECIAL_CARD = 'special_card',
  SUPPLIER = 'supplier',
  ARTICLE = 'article',
}

export enum UiPlacementPlacement {
  INSPIRATION_TAG_FILTER = 'inspiration_tag_filter', // @deprecated, replaced with REAL_WEDDING_TAG_FILTER
  INSPIRATION_LOCATION_TAG_FILTER = 'inspiration_location_tag_filter', // @deprecated, replaced with REAL_WEDDING_LOCATION_TAG_FILTER
  REAL_WEDDING_TAG_FILTER = 'real_wedding_tag_filter',
  REAL_WEDDING_LOCATION_TAG_FILTER = 'real_wedding_location_tag_filter',
  INSPIRATION_QUICK_FILTER = 'inspiration_quick_filter',
  PLANNING_QUICK_FILTER = 'planning_quick_filter',
  SUPPLIER_SEARCH_BUSINESS_CATEGORY_FILTER = 'supplier_search_business_category_filter',
  VENUES_TAG_FILTER = 'venues_tag_filter',
  VENUES_CAROUSEL_TAG_FILTER = 'venues_carousel_tag_filter',
  LANDING_PAGE_BC_CAROUSEL = 'landing_page_bc_carousel',
  MAIN_MENU = 'main_menu',
  SUB_MENU = 'sub_menu',
  CATEGORY_PAGE_DATA = 'category_page_data',
  SUPPLIER_TOP_NAV_LIST = 'supplier_top_nav_list',
  VENUE_TOP_NAV_LIST = 'venue_top_nav_list',
  HOMEPAGE_BANNER_IMAGE = 'homepage_banner_image',
}

export type UiPlacementBusinessCategoryId = number
export type UiPlacementTagCategoryId = number
export type UiPlacementSortOrder = number

export type SpecialCard = {
  article: Article
  article_id: number
  id: number
  theme: 'light' | 'dark'
  type: string
  ui_placement_id: string
}

export type BannerCard = {
  id: number
  image: Image
  title: string
  button_text: string
  button_link: string
  image_id: number
}

export type UiPlacementArticle = Pick<
  Article,
  | 'id'
  | 'title'
  | 'hero_image'
  | 'hero_image_ref'
  | 'banner_image'
  | 'banner_image_id'
  | 'article_categories'
  | 'article_subcategories'
  | 'published_date'
  | 'path'
>

export interface UiPlacement {
  id?: number
  placement: UiPlacementPlacement
  type: UiPlacementType
  business_category_id?: UiPlacementBusinessCategoryId
  business_category?: BusinessCategory | null
  tag_category_id?: UiPlacementTagCategoryId
  tag_category?: TagCategory | null
  tag_id?: number
  tag?: Tag | null
  article_category_id?: string
  article_subcategory_id?: string
  metadata?: {
    slug?: string
    name?: string
    rowTitle?: string
    showSuppliers?: boolean
    showVenues?: boolean
    overrides?: {
      article_category_id?: string
      article_subcategory_id?: string
      slug?: string
    }
  }
  article_categories?: ArticleCategory
  article_subcategories?: ArticleSubcategory
  sort_order: UiPlacementSortOrder
  special_card?: SpecialCard
  banner_card?: BannerCard
  supplier_id?: number
  supplier?: Supplier | null
  article_id?: number
  article?: UiPlacementArticle | null
}

export interface UiPlacementResponse {
  data: {
    [key: string]: {
      [innerKey: string]: UiPlacement[]
    }
  }
}
