import { SectionHeader } from '@/components/v2/atoms/section-header/section-header'
import s from './not-found-content.module.css'
import { ROUTES } from '@/constants'
import { Button } from '@/components/v2/atoms/button/button'
import { Article } from '@/types/Article'
import { ArticleSlider } from '@/components/v2/molecules/custom-slider/article-slider/article-slider'

type Props = {
  articles: Article[]
}

export const NotFoundContent = ({ articles }: Props) => {
  return (
    <>
      <SectionHeader style={{ textAlign: 'left', margin: '21px 0' }}>
        Our Latest Articles And Additions
      </SectionHeader>
      <p className={s.text}>
        Real wedding inspiration, expert planning advice, and trusted
        Recommended Venues and Suppliers, what will take your fancy today?
      </p>
      <h4 className={s.smallHeader}>
        Rock My Wedding has you covered every step of the way!
      </h4>
      <ArticleSlider articles={articles} />
      <div className={s.ctaContainer}>
        <Button href={ROUTES.WEDDING_INSPIRATION} className={s.cta}>
          explore all articles
        </Button>
      </div>
    </>
  )
}
