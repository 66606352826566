import dynamic from 'next/dynamic'

export type APIUserType = {
  CREATE: string
  UPDATE_USER: (id: any) => string
}

export const CSS_BREAKPOINTS = {
  small: '599px',
  medium: '959px',
  large: '990px',
  xlarge: '1200px',
}

export const CSS_BREAKPOINTS_V2 = {
  tablet: '768px',
  desktopS: '1024px',
  desktopM: '1280px',
  desktopL: '1440px',
}

export const CHAT_ROOM_FILTER_KEYS = {
  COLLABORATION: 'collaboration',
  BROCHURE: 'brochure',
  ENQUIRY: 'enquiry',
  ARCHIVED: 'archived',
  ALL: 'all',
} as const

export const CUSTOMER_MESSAGE_TYPES_FILTER_KEYS = {
  ARCHIVED: 'archived',
  RECYCLE: 'recycle',
} as const

export const CHAT_ROOM_SORT_KEYS = {
  DESC: 'desc',
  ASC: 'asc',
  UNREAD: 'unread',
  RESPONDED: 'responded',
} as const

type Keys<T> = keyof T
type ValuesType<T> = T[Keys<T>]

type ChatRoomQueryType = {
  page: number
  limit: number
  keyword?: string
  messageType?: ValuesType<typeof CHAT_ROOM_FILTER_KEYS>
  businessCategoryId?: any
  sortKey: ValuesType<typeof CHAT_ROOM_SORT_KEYS>
}

type ImageLibraryType = {
  ALL: string
  BY_ID: (id: number) => string
}

export const stepViews: { [key: number]: any } = {
  1: { step: 1, value: 'form' },
  2: { step: 2, value: 'pricing' },
}

export const optionsSortBy = [
  {
    label: 'Oldest - newest',
    value: CHAT_ROOM_SORT_KEYS.ASC,
  },
  {
    label: 'Newest - oldest',
    value: CHAT_ROOM_SORT_KEYS.DESC,
  },
  {
    label: 'Unread',
    value: CHAT_ROOM_SORT_KEYS.UNREAD,
  },
]

export const optionFilterBy = [
  {
    label: 'All',
    value: CHAT_ROOM_FILTER_KEYS.ALL,
  },
  {
    label: 'Brochure request',
    value: CHAT_ROOM_FILTER_KEYS.BROCHURE,
  },
  {
    label: 'Collaboration request',
    value: CHAT_ROOM_FILTER_KEYS.COLLABORATION,
  },
  {
    label: 'Archived',
    value: CHAT_ROOM_FILTER_KEYS.ARCHIVED,
  },
  {
    label: 'Enquiry',
    value: CHAT_ROOM_FILTER_KEYS.ENQUIRY,
  },
]

export const customerMessageTypeOptionFilterBy = [
  {
    label: 'Archived',
    value: CUSTOMER_MESSAGE_TYPES_FILTER_KEYS.ARCHIVED,
  },
  {
    label: 'Recycle',
    value: CUSTOMER_MESSAGE_TYPES_FILTER_KEYS.RECYCLE,
  },
]

export const CONSOLE_DEEPER = {
  SUPPLER_LISTINGS: 'supplier-listings',
  SUPPLER_TAGS: 'supplier-tags',
  EDITOR_CONTENT: 'content',
  SUPPLIER_ACCOUNT: 'supplier-account',
  COMPOSER: 'composer',
}

export type ADMIN_API = {
  GET_SUPPLIER_LISTINGS: (
    page: number,
    query: string,
    status: string[] | null,
    business_category_id: string[] | null
  ) => string
  GET_SUPPLIER_ID: (id: string) => string
  GET_CUSTOMER_LISTING: (page: number, search: string) => string
  DELETE_CUSTOMER: (id: number) => string
  EXPORT_CUSTOMER: string
  GET_RECYCLE_LISTINGS: (
    page: number,
    category: string,
    payment_status: string,
    item_status: string,
    active: string,
    filter: string,
    approval_statuses: string
  ) => string
  GET_RECYCLE_BY_ID: (id: number) => string
  UPDATE_RECYCLE_BY_ID: (id: number) => string
  PUT_SUPPLIER_ID: (id: number) => string
  GET_FEATURE_SERVICE: (page: number, filter: string) => string
  GET_FEATURE_SERVICE_BY_TAG: (page: number, tag: string) => string
  CREATE_NEW_TAG: string
  CREATE_SUPPLIER_TAG: string
  DELETE_SUPPLIER_TAG: (id: number) => string
  GET_MEMBER_LIST: (page: number, search: string) => string
  UPDATE_SUPPLIER_ACCOUNT_BY_ID: (id: number) => string
  GET_SUPPLIER_MANAGER_LIST: string
  GET_LISTING_BY_ACCOUNT: (id: number, page: number) => string
  SUPPLIER_NUDGE_APPLY: (id: number) => string
  DELETE_MEMBER: (id: number) => string
  UPDATE_MEMBER_BY_ID: (id: number) => string
  GET_RECYCLE_EXPORT: string
  EXPORT_SUPPLIER_ACCOUNT: (type: string) => string
  GET_MEMBER_EXPORT: string
  GET_TAG_BY_SECTION: (section: string) => string
  CREATE_TAG_ARTICLE: string
  SUPPLIER_NUDGE_LISTING: (id: number) => string
}

export type APIType = {
  UPDATE_LISTING_SUBSCRIPTION_BY_SUPPLIER_ID: (id: number) => string
  GET_RECYCLES_RECOMMENDED: string
  GET_CUSTOMER_PORTAL_STRIPE: string
  GET_SIGN_UP_LIST: string
  GET_BILLING_INVOICE: string
  GET_LOCATION_LIST: string
  GET_REGION_LIST: string
  GET_GUEST_LIST: string
  GET_BUDGET_LIST: string
  GET_ONBOARDING_FORM_TWO: string
  GET_MONTHLY_PRICE_FIRST_PAYMENT_DATE: string
  CUSTOMER_EVENT: string
  USERS: APIUserType
  GET_TAG: String
  GET_TAG_BY_CATEGORY_AND_SECTION: (category: string, section: string) => string
  GET_TAG_BY_CATEGORY: (category: string) => string
  DELETE_COLLABORATOR: (id: number, supplierID: number) => string
  CREATE_SUPPLIER: string
  CHECK_EMAIL_EXIST: string
  GET_BLOCK_TYPE: string
  CREATE_PAYMENT_INTENT: string
  UPDATE_SUPPLIER_LISTING: (id: string) => String
  PUT_SUPPLIER: (id: string) => string
  REQUEST_COLLABORATOR: (id: string) => string
  GET_SUPPLIER: string
  GET_ARTICLE_SUPPLIER: string
  GET_CUSTOMER_EVENTS_SUPPLIER: string
  CREATE_NEW_SUPPLIER: string
  SUBMIT_FOR_APPROVAL: (id: number) => string
  GET_CHAT_ROOM: ({
    businessCategoryId,
    page,
    limit,
    keyword,
    messageType,
    sortKey,
  }: ChatRoomQueryType) => string
  SEARCH_CHAT_ROOM: string
  FAVOURITE: string
  POST_NEW_MESSAGE: string
  MARK_MESSAGE: string
  COLLABORATION_RESPONSE: string
  GET_CONVERSTATION: (id: number) => string
  CREATE_CONVERSTATION: (id: number) => string
  UPDATE_CHATROOM_STATUS: (id: number) => string
  GET_CHATROOM_CHAT: string
  ARCHIVE_CHAT: string
  GET_DIVERSITIES: string
  ADD_NEW_TASK: string
  GET_CHECKLIST_TASKS: string
  GET_ARTICLES: string
  UPDATE_CHECKLIST_TASKS: (timeBefore: number, id: number) => string
  GET_RECYCLES: (
    page: number,
    sort: string,
    type: string,
    filter?: number[],
    category?: number,
    exclude_sold?: boolean
  ) => string
  DELETE_CHECKLIST_TASKS: (timeBefore: number, id: number) => string
  GET_SUPPLIER_BY_ID: (id: number) => string
  GET_RECYCLE_CATEGORIES: string
  POST_RECYCLE: string
  GET_RECYCLE_BY_ID: (id: string) => string
  UPDATED_RECYCLE: (id: string) => string
  UPDATED_RECYCLE_STATUS: (id: string) => string
  ADMIN: ADMIN_API
  CONTACT_RECYCLE: string
  GET_IMAGE_LIBRARY: ImageLibraryType
  ARTICLE: {
    ADMIN_GET: (params: string) => string
    GET: (params: string) => string
    GET_BY_ID: (id: string) => string
    POST: string
    PUT: (id: string) => string
  }
}

export type SupplierType = {
  LISTING: (form: string) => string
  DASHBOARD: string
}

export type OnboardingType = {
  BOARD: (board: string) => string
}

export type ConsoleType = {
  EDITOR: (deepUrl: string) => string
  RECYCLE: string
  CUSTOMER: string
  MEMBER: string
  SUPPLIERS: (deepUrl: string) => string
  MEDIA_LIBRARY: string
}

export type AdminType = {
  HOME: string
  CONSOLE: ConsoleType
}

export type DomainType = {
  CREATE: string
  EDIT: string
  NOT_FOUND: string
  SIGN_UP: string
  SIGN_IN: string
  AUTH: string
  ADMIN_PORTAL: AdminType
  ONBOARDING: OnboardingType
  HOME: string
  SUPPLIER: SupplierType
  API: APIType
  SUPPLIER_THANK_YOU: string
  SUPPLIER_MEMBERSHIP: string
  CONGRATULATIONS: string
  SUPPLIER_SUMMARY: string
  SUPPLIER_MESSAGES: string
  SUPPLIER_ACCOUNT: string
  SUPPLIER_LISTINGS: string
  SUPPLIER_BUS_HUB: string
  SUPPLIER_SUB_REAL_WED: string
  CUSTOMER_DASHBOARD: (page: string) => string
  RECYCLE: {
    ID: (id: number) => string
  }
  SEARCH: string
  VENUE: string
  LANDING_PAGE: string
  SEND_RESET_PASSWORD: string
  EXPLORE_ALL_PODCAST: string
}

export const DOMAIN_ACTION: DomainType = {
  CREATE: 'create',
  EDIT: 'edit',
  NOT_FOUND: '404',
  SIGN_UP: '/auth?step=1',
  SIGN_IN: '/auth?step=2',
  SEND_RESET_PASSWORD: '/forgetten-password',
  AUTH: '/auth',
  ADMIN_PORTAL: {
    HOME: '/admin',
    CONSOLE: {
      EDITOR: (deepUrl: string = '') => `/admin/editor-console${`/${deepUrl}`}`,
      RECYCLE: '/admin/recycle-console',
      CUSTOMER: '/admin/customer-console',
      MEMBER: '/admin/member-console',
      SUPPLIERS: (deepUrl: string = '') =>
        `/admin/supplier-console${`/${deepUrl}`}`,
      MEDIA_LIBRARY: '/admin/media-library',
    },
  },
  SUPPLIER_MEMBERSHIP: '/supplier/membership',
  SUPPLIER_THANK_YOU: '/supplier/thankyou',
  SUPPLIER_MESSAGES: '/supplier/dashboard/messages',
  SUPPLIER_SUMMARY: '/supplier/dashboard/summary',
  SUPPLIER_ACCOUNT: '/supplier/dashboard/account?tab=account',
  SUPPLIER_LISTINGS: '/supplier/dashboard/listings',
  SUPPLIER_BUS_HUB: '/supplier/dashboard/business-hub',
  SUPPLIER_SUB_REAL_WED: '/supplier/dashboard/submit-real-wedding',
  HOME: '/',
  CONGRATULATIONS: '/supplier/congrats',
  SUPPLIER: {
    LISTING: (form: string) => `/supplier/listing?formName=${form}`,
    DASHBOARD: '/supplier/dashboard',
  },
  ONBOARDING: {
    BOARD: (board: string) => `/onboarding?board=${board}`,
  },
  CUSTOMER_DASHBOARD: (page: string) => `/customer/dashboard/${page}`,
  RECYCLE: {
    ID: (id: number) => `/recycle/${id}`,
  },
  SEARCH: '/search',
  LANDING_PAGE: '/',
  VENUE: 'venue',
  API: {
    UPDATE_LISTING_SUBSCRIPTION_BY_SUPPLIER_ID: (id: number) =>
      `/api/updateSubscription/${id}`,
    GET_RECYCLES_RECOMMENDED: '/recycles/recommended',
    GET_REGION_LIST: '/api/regions',
    GET_BILLING_INVOICE: '/api/getBillingInvoice',
    GET_CUSTOMER_PORTAL_STRIPE: '/api/customerPortalStripeSession',
    GET_SIGN_UP_LIST: '/api/getSignupList',
    GET_LOCATION_LIST: '/api/getLocations',
    GET_GUEST_LIST: '/customer-events/number-of-guests',
    GET_BUDGET_LIST: '/customer-events/ideal-budget',
    GET_ONBOARDING_FORM_TWO: '/api/getOnbaordingFormTwo',
    CHECK_EMAIL_EXIST: '/api/postCheckEmailExist',
    GET_BLOCK_TYPE: ' /api/getBlockType',
    REQUEST_COLLABORATOR: (id: string) => `/api/requestCollaborator/${id}`,
    USERS: {
      CREATE: '/api/user',
      UPDATE_USER: (id) => `/api/user/${id}`,
    },
    PUT_SUPPLIER: (id: string) => `/api/supplier/${id}`, // Endpoint for upload supplier
    GET_SUPPLIER_BY_ID: (id: number) => `/api/supplier/get/${id}`, // Endpoint for upload supplier,
    UPDATE_SUPPLIER_LISTING: (id: string = '') =>
      `/api/updateSupplierAccountDetails/${id}`,
    CUSTOMER_EVENT: '/api/putCustomerEvent',
    GET_TAG: '/tags',
    GET_TAG_BY_CATEGORY_AND_SECTION: (category: string, section: string) =>
      `/api/getTags?category=${category}&section=${section}`,
    GET_TAG_BY_CATEGORY: (category: string) =>
      category
        ? `/api/getTags?category=${category}&section=supplier`
        : `/api/getTags`,
    CREATE_SUPPLIER: '/api/createSupplier',
    CREATE_PAYMENT_INTENT: '/api/createPaymentIntent',
    GET_SUPPLIER: '/api/supplier/list',
    GET_MONTHLY_PRICE_FIRST_PAYMENT_DATE: '/api/getMonthlyPriceAndFirstPayment',
    GET_ARTICLE_SUPPLIER: '/api/supplier/article/{slug}',
    DELETE_COLLABORATOR: (id: number, supplier_id: number) =>
      `/api/deleteCollaborator/${id}/${supplier_id}`,
    POST_NEW_MESSAGE: '/api/postMessage',
    MARK_MESSAGE: '/api/markMessage',
    COLLABORATION_RESPONSE: '/api/collaborationResponse',
    ARCHIVE_CHAT: '/api/archiveChat',
    GET_CUSTOMER_EVENTS_SUPPLIER: '/api/customer/customer-events-supplier',
    CREATE_NEW_SUPPLIER: '/api/createSupplier/newSuppler',
    SUBMIT_FOR_APPROVAL: (id: number) => `/api/submitForApproval/${id}`,
    GET_CHAT_ROOM: ({
      businessCategoryId,
      page,
      limit,
      keyword,
      messageType,
      sortKey,
    }) =>
      `/api/getChatRoom?businessCategoryId=${businessCategoryId}&page=${page}&limit=${limit}&keyword=${keyword}&messageType=${messageType}&sortKey=${sortKey}`,
    SEARCH_CHAT_ROOM: '/api/searchChatRoom',
    FAVOURITE: '/api/addFavourite',
    GET_CHATROOM_CHAT: `/api/getChatroomChat`,
    GET_CONVERSTATION: (id: number) => `/api/message/${id}/conversations`,
    CREATE_CONVERSTATION: (id: number) => `/api/message/${id}/conversation`,
    UPDATE_CHATROOM_STATUS: (id: number) => `/api/message/${id}`,
    GET_DIVERSITIES: '/api/getDiversities',
    ADD_NEW_TASK: '/api/addNewChecklist',
    GET_CHECKLIST_TASKS: '/api/getChecklistTasks',
    GET_ARTICLES: `/articles`,
    UPDATE_CHECKLIST_TASKS: (timeBefore, id) =>
      `/api/updateChecklist?timeBefore=${timeBefore}&id=${id}`,
    GET_RECYCLES: (page, sort, type, filter?, category?, exclude_sold?) =>
      `/api/recycle/getRecycles?page=${page}&sort=${sort}&type=${type}${
        filter ? (filter?.length > 0 ? `&filter=[${filter}]` : '') : ''
      }${category ? `&category=${category}` : ''}${
        exclude_sold ? `&exclude_sold=${exclude_sold}` : ''
      }`,
    DELETE_CHECKLIST_TASKS: (timeBefore, id) =>
      `/api/deleteChecklist?timeBefore=${timeBefore}&id=${id}`,
    GET_RECYCLE_CATEGORIES: '/api/recycle/getCategory',
    POST_RECYCLE: '/api/recycle/createRecycle',
    GET_RECYCLE_BY_ID: (id) => `/api/recycle/getRecycles/${id}`,
    UPDATED_RECYCLE: (id) => `/api/recycle/putRecycle/${id}`,
    UPDATED_RECYCLE_STATUS: (id) => `/api/recycle/putRecycleStatus/${id}`,
    ADMIN: {
      GET_SUPPLIER_LISTINGS: (page, query, status, business_category_id) =>
        `/api/admin/supplier/getListings?page=${page}&query=${query}&status=${status}&business_category_id=${business_category_id}`,
      GET_SUPPLIER_ID: (id) => `/api/admin/supplier/${id}`,
      PUT_SUPPLIER_ID: (id) => `/api/admin/supplier/updateSupplier/${id}`,
      GET_FEATURE_SERVICE: (page, filter) =>
        `/api/admin/tags/getFeatureService?page=${page}&filter=${filter}`,
      GET_FEATURE_SERVICE_BY_TAG: (page, tag) =>
        `/api/admin/tags/getFeatureServiceByTag?page=${page}&tag=${tag}`,
      CREATE_NEW_TAG: '/api/admin/tags/createTag',
      GET_CUSTOMER_LISTING: (page, search) =>
        `/api/admin/customer/getListing?page=${page}&search=${search}`,
      DELETE_CUSTOMER: (id) => `/api/admin/customer/deleteCustomer/${id}`,
      EXPORT_CUSTOMER: `/api/admin/customer/exportCustomer`,
      GET_RECYCLE_LISTINGS: (
        page,
        category,
        payment_status,
        item_status,
        active,
        filter,
        approval_statuses
      ) =>
        `/api/admin/recycle/getListings?page=${page}&category=${category}&filter=${filter}&payment_status=${payment_status}&item_status=${item_status}&approval_statuses=${approval_statuses}&active=${active}`,
      GET_RECYCLE_BY_ID: (id) => `/api/admin/recycle/${id}`,
      UPDATE_RECYCLE_BY_ID: (id) => `/api/admin/recycle/updateRecycle/${id}`,
      CREATE_SUPPLIER_TAG: '/api/admin/supplier/createTag',
      DELETE_SUPPLIER_TAG: (id) => `/api/admin/supplier/deleteTag/${id}`,
      GET_MEMBER_LIST: (page, search) =>
        `/api/admin/member/getMemberList?page=${page}&search=${search}`,
      UPDATE_SUPPLIER_ACCOUNT_BY_ID: (id) =>
        `/api/admin/supplier/updateSupplierAccount/${id}`,
      GET_SUPPLIER_MANAGER_LIST: '/api/admin/supplier/getListManager',
      GET_LISTING_BY_ACCOUNT: (id, page) =>
        `/api/admin/supplier/getListingByAccount?page=${page}&id=${id}`,
      SUPPLIER_NUDGE_APPLY: (id) => `/api/admin/supplier/nudge/apply/${id}`,
      DELETE_MEMBER: (id) => `/api/admin/member/deleteMember/${id}`,
      UPDATE_MEMBER_BY_ID: (id) => `/api/admin/member/updateMember/${id}`,
      GET_RECYCLE_EXPORT: `/api/admin/recycle/export`,
      EXPORT_SUPPLIER_ACCOUNT: (type) =>
        `/api/admin/supplier/exportAccount?type=${type}`,
      GET_MEMBER_EXPORT: `/api/admin/member/export`,
      GET_TAG_BY_SECTION: (section: string) =>
        section
          ? `/api/admin/composer/getTags?section=${section}`
          : `/api/getTags`,
      CREATE_TAG_ARTICLE: 'api/admin/composer/createTag',
      SUPPLIER_NUDGE_LISTING: (id) => `/api/admin/supplier/nudge/listing/${id}`,
    },
    CONTACT_RECYCLE: '/api/recycle/contactRecycle',
    GET_IMAGE_LIBRARY: {
      ALL: '/api/getImageLibrary/all',
      BY_ID: (id: number) => `/api/getImageLibrary/${id}`,
    },
    ARTICLE: {
      GET: (param: string) => `/api/articles/get${param}`,
      GET_BY_ID: (id: string) => `/api/articles/get/${id}`,
      POST: '/api/articles/post',
      PUT: (id: string) => `/api/articles/put/${id}`,
      ADMIN_GET: (param: string) => `/api/articles/admin${param}`,
    },
  },
  EXPLORE_ALL_PODCAST: '/wedding-podcast',
}

export interface LocationSingle {
  abbreviation: string
  country_id: number
  created_at: string
  id: number
  image_id: number
  name: string
  region_id: number
  updated_at: string
}

export type WeddingType = {
  YEAR_SEASON: string
  YEAR_MONTH: string
  DATE: string
}

export const WEDDING_TYPE: WeddingType = {
  YEAR_SEASON: 'year_season',
  YEAR_MONTH: 'year_month',
  DATE: 'date',
}

export const weddingType: { [key: number]: string } = {
  1: WEDDING_TYPE.YEAR_SEASON,
  2: WEDDING_TYPE.YEAR_MONTH,
  3: WEDDING_TYPE.DATE,
}

export const statusMapping = {
  CREATED: 'CREATED',
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  DECLINED: 'DECLINED',
} as const

export const SubmitForReviewLabel: string = 'Submit for review'

export const BlockTypeMaping: any = {
  1: 'content',
  2: 'image_grid',
  3: 'quote',
  4: 'horizontal_rule',
  5: 'column',
  6: 'featured_suppliers',
  7: 'cta',
  8: 'html',
  9: 'single_image',
  10: 'content',
  11: 'content',
  12: 'button',
}

export const CURRENCY_UNIT = {
  GBP: '£',
  USD: '$',
  THB: '฿',
}

export const OptionsSeasion = [
  {
    value: 'summer',
    label: 'Summer',
  },
  {
    value: 'autumn',
    label: 'Autumn',
  },
  {
    value: 'winter',
    label: 'Winter',
  },
  {
    value: 'spring',
    label: 'Spring',
  },
]

export const OptionsMonth = [
  {
    value: 1,
    label: 'January',
  },
  {
    value: 2,
    label: 'February',
  },
  {
    value: 3,
    label: 'March',
  },
  {
    value: 4,
    label: 'April',
  },
  {
    value: 5,
    label: 'May',
  },
  {
    value: 6,
    label: 'June',
  },
  {
    value: 7,
    label: 'July',
  },
  {
    value: 8,
    label: 'August',
  },
  {
    value: 9,
    label: 'September',
  },
  {
    value: 10,
    label: 'October',
  },
  {
    value: 11,
    label: 'November',
  },
  {
    value: 12,
    label: 'December',
  },
]

export const OptionsDateWedding = [
  {
    value: 0,
    label: 'I know my wedding...',
    type: '',
  },
  {
    value: 1,
    label: 'Season / year',
    type: 'year_season',
  },
  {
    value: 2,
    label: 'Month / year',
    type: 'year_month',
  },
  {
    value: 3,
    label: 'Date',
    type: 'date',
  },
]

export const SupplierListingMenu = [
  { name: 'Account Details', value: 'account-details' },
  { name: 'Listing Details', value: 'listing-details' },
  { name: 'Features & services', value: 'features-and-services' },
  { name: 'Gallery', value: 'gallery' },
  { name: 'Social Profiles', value: 'social-profiles' },
  { name: 'Testimonials', value: 'testimonials' },
  { name: 'Offers & promotions', value: 'offers-and-promotions' },
  { name: 'FAQs', value: 'faqs' },
  { name: 'Events', value: 'events' },
  { name: 'Collaborators', value: 'collaborators' },
]

export const formErrorMessage: { [key: string]: string } = {
  listing_name: 'Listing name field is required.',
  listing_name_exists: 'Listing name already exists.',
  listing_summary: 'The listing summary field is required.',
  business_description: 'The business description field is required.',
  gallery_list: 'The photos are required at least 8 photos.',
  feature_images: 'The Header image is required.',
  business_category: 'The Suppler/Business category field is required',
}

export const AccountTab = [
  { name: 'Details', value: 'account', path: '?tab=account' },
  {
    name: 'Membership & Billing',
    value: 'membership',
    path: '?tab=membership',
  },
]

export const MESSAGE_TAB = [
  { name: 'All Messages', value: 'all-message', path: '?tab=all-message' },
  {
    name: 'Message Thread ',
    value: 'message-thread',
    path: '?tab=message-thread',
  },
]

export const SupplierDashboard = [
  { name: 'My Dashboard', value: 'summary' },
  { name: 'My Messages', value: 'messages', queryString: MESSAGE_TAB },
  {
    name: 'My Account',
    value: 'account',
    queryString: AccountTab,
  },
  { name: 'My Listings', value: 'listings' },
  { name: 'Submit Real Wedding', value: 'submit-real-wedding' },
]

export const Components: any = {
  content: dynamic(() => import('@/components/v1/blocks/content'), {
    ssr: true,
  }),
  horizontal_rule: dynamic(
    () => import('@/components/v1/blocks/horizonalRule'),
    {
      ssr: true,
    }
  ),
  image_grid: dynamic(() => import('@/components/v1/blocks/content'), {
    ssr: true,
  }),
  quote: dynamic(() => import('@/components/v1/blocks/quote'), { ssr: true }),
  cta: dynamic(() => import('@/components/v1/blocks/content'), { ssr: true }),
  column: dynamic(() => import('@/components/v1/blocks/columns'), {
    ssr: true,
  }),
}

export const ACTION_VIEW = {
  DESKTOP: 'desktop',
  TABLET: 'tablat',
  MOBILE: 'mobile',
}

export const OnboardingPage = [
  { name: 'Onboarding board one', value: 'event' },
  { name: 'Onboarding board two', value: 'tag' },
  { name: 'Onboarding board two', value: 'category' },
]

export const DATE_FORMAT = {
  DD_MMM_YYYY: 'DD MMM YYYY',
  DD_MM_YYYY: 'DD/MM/YYYY',
  YYYY_MM_DD: 'YYYY/MM/DD',
  YYYY_MM_DD_2: 'YYYY-MM-DD',
}
export const VenueId = 18

export const MESSAGE_TYPE = {
  BROCHURE: 'brochure',
  ENQUIRY: 'enquiry',
}

export const FAVOURITE_TAB = [
  { name: 'All', value: 'all', path: '?tab=all' },
  {
    name: 'Inspiration & Planning',
    value: 'article',
    path: '?tab=article',
  },
  {
    name: 'Venues',
    value: 'venue',
    path: '?tab=venue',
  },
  {
    name: 'Suppliers',
    value: 'supplier',
    path: '?tab=supplier',
  },
  {
    name: 'Recycle',
    value: 'recycle',
    path: '?tab=recycle',
  },
]

export const CUSTOMER_ACCOUNT_TAB = [
  { name: 'Details', value: 'details', path: '?tab=details' },
]

export const CUSTOMER_RECYCLE_TAB = [
  { name: 'Buying', value: 'buy', path: '?tab=buy&page=1' },
  { name: 'Selling', value: 'sell', path: '?tab=sell&page=1' },
]

export const CustomerDashboard = [
  { name: 'Dashboard', value: 'summary' },
  // temporary hide my suppliers and checklist by removing it's names
  { name: '', value: 'my-suppliers' },
  {
    name: '',
    value: 'checklist',
  },
  { name: 'Favourites', value: 'favourites', queryString: FAVOURITE_TAB },
  { name: 'Messages', value: 'messages', queryString: MESSAGE_TAB },
  {
    name: 'my account',
    value: 'my-account',
    queryString: CUSTOMER_ACCOUNT_TAB,
  },
  { name: 'Recycle', value: 'recycle', queryString: CUSTOMER_RECYCLE_TAB },
]

export const CHAT_TYPE = {
  ENQUIRY: 'enquiry',
  BROCHURE: 'brochure',
  COLLABORATION_PENDING: 'collaboration_pending',
  COLLABORATION_ACCEPTED: 'collaboration_accepted',
  COLLABORATION_DECLINED: 'collaboration_declined',
}

export const internationalID = 5

export const Season = {
  WINTER: 'winter',
  SPRING: 'spring',
  SUMMER: 'summer',
  AUTUMN: 'autumn',
}

export const CHECKLIST_BUTTON_TYPE = {
  ADD_TASK: 'add_task',
  FILTERS: 'filters',
  CLEAR: 'clear',
  APPLY: 'apply',
}

export const CUSTOMER_CHECKLIST_FILTERS = [
  { label: '12+ months', value: 360 },
  { label: '9 months', value: 270 },
  { label: '6 months', value: 180 },
  { label: '4 months', value: 120 },
  { label: '2 months', value: 60 },
  { label: '1 month', value: 30 },
  { label: '1 week', value: 7 },
]

export enum FAVOURITE {
  ARTICLE = 'ARTICLE',
  SUPPLIER = 'SUPPLIER',
  RECYCLE = 'RECYCLE',
  VENUE = 'VENUE',
}

export const CHECKLIST_MODAL_BUTTON_TYPE = {
  ADD_TASK: 'add_task',
  CANCEL: 'cancel',
  DELETE: 'delete',
}

export const RECYCLE_TYPE = {
  BUY: 'BUY',
  SELL: 'SELL',
}

export const SORT_BY = {
  BY_CREATED_DESC: 'BY_CREATED_DESC',
  BY_LATEST_UPDATED: 'BY_LATEST_UPDATED',
  BY_LOW_PRICE_TO_HIGH: 'BY_LOW_PRICE_TO_HIGH',
  BY_HIGH_PRICE_TO_LOW: 'BY_HIGH_PRICE_TO_LOW',
}

export type RecycleSortByKeys = keyof typeof SORT_BY

type IRECYCLE_FORM_TYPE = {
  [key: string]: string
}

export const RECYCLE_FORM_TYPE: IRECYCLE_FORM_TYPE = {
  CREATE: 'create',
  EDIT: 'edit',
}

export const STATUS_SELL = {
  PUBLISHED: 'PUBLISHED',
  UNPUBLISHED: 'UNPUBLISHED',
  SOLD: 'SOLD',
}

export const SUPPLIER_FILTERS = {
  CREATED: 'CREATED',
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  PAID: 'PAID',
  DECLINED: 'DECLINED',
  INACTIVE: 'INACTIVE',
}

export { widgetIndex, widgetsData } from './widget'

export const STRIPE_LINK_RECYCLE = `${
  process.env.NEXT_PUBLIC_STRIPE_DASHBOARD_URL
}${
  process.env.NEXT_PUBLIC_APP_ENV === 'PROD'
    ? '/login?redirect=/payments/'
    : '/login?redirect=/test/payments/'
}`

export const STRIPE_LINK_CUSTOMER = `${
  process.env.NEXT_PUBLIC_STRIPE_DASHBOARD_URL
}${
  process.env.NEXT_PUBLIC_APP_ENV === 'PROD'
    ? '/customers/'
    : '/test/customers/'
}`

const ROUTE_CUSTOMER_ROOT = '/customer'
const ROUTE_PREVIEW_ROOT = '/preview'
const ROUTE_SUPPLIER = '/supplier'
export const ROUTES = {
  ABOUT_US: '/about-us',
  ADMIN: {
    ROOT: '/admin',
    NEW_FEATURES: '/admin/new-features',
    CATEGORIES: '/admin/categories',
    SUBCATEGORIES: '/admin/subcategories',
    SUPPLIER_AND_VENUE_CARDS: '/admin/supplier-and-venue-cards',
    SUPPLIER_STATS: '/admin/supplier-stats',
    HOMEPAGE_HERO_SECTION: '/admin/homepage-hero-section',
  },
  AFFILIATES: '/affiliates',
  ARTICLE: {
    ROOT: '/article',
  },
  CONTACT_US: '/contact-us',
  CUSTOMER: {
    CHECKLIST: ROUTE_CUSTOMER_ROOT + '/dashboard/checklist',
    DASHBOARD: ROUTE_CUSTOMER_ROOT + '/dashboard/summary',
    FAVOURITES: ROUTE_CUSTOMER_ROOT + '/dashboard/favourites',
    MESSAGES: ROUTE_CUSTOMER_ROOT + '/dashboard/messages',
    MY_SUPPLIERS: ROUTE_CUSTOMER_ROOT + '/dashboard/my-suppliers',
    RECYCLE: ROUTE_CUSTOMER_ROOT + '/dashboard/recycle',
    ROOT: ROUTE_CUSTOMER_ROOT,
  },
  HOME: '/',
  INTERNAL_SERVER_ERROR: '/500',
  NOT_FOUND: '/404',
  PODCAST: {
    ROOT: '/wedding-podcast',
  },
  PREVIEW: {
    ARTICLE: ROUTE_PREVIEW_ROOT + '/article',
    ROOT: ROUTE_PREVIEW_ROOT,
  },
  PRIVACY: '/privacy',
  READER: {
    DASHBOARD: '/customer/dashboard/summary',
    ONBOARDING: '/onboarding',
    MY_ACCOUNT: '/customer/dashboard/my-account',
  },
  RECYCLE: {
    HOME: '/recycle-your-wedding',
    LIST: '/recycle/marketplace',
    ROOT: '/recycle',
  },
  SEARCH: '/search',
  SIGN_IN: '/auth?step=2',
  SIGN_UP: '/auth?step=1',
  SUPPLIER: {
    LISTING: ROUTE_SUPPLIER + '/listing',
    ROOT: ROUTE_SUPPLIER,
    SUMMARY: ROUTE_SUPPLIER + '/dashboard/summary',
  },
  TERMS: '/terms',
  VENUE: {
    ROOT: '/wedding-venues',
  },
  WEDDING_INSPIRATION: '/wedding-inspiration',
  WEDDING_PLANNING: '/wedding-planning',
  WEDDING_SUPPLIERS: {
    APPLY: {
      FORM: '/supplier-application?view=form',
      PRICING: '/supplier-application?view=pricing',
      ROOT: '/supplier-application',
    },
    ROOT: '/wedding-suppliers',
  },
  WORK_WITH_US: '/work-with-us',
  MESSAGES: {
    ALL_MESSAGES: '/customer/dashboard/messages?tab=all-message',
    THREAD: '/customer/dashboard/messages?tab=message-thread',
  },
}

export const API_V1 = `${process.env.NEXT_PUBLIC_BASE_URL}/api/v1`
export const API_V2 = `${process.env.NEXT_PUBLIC_BASE_URL}/api/v2`
export const FE_URL = process.env.NEXT_PUBLIC_BASE_URL

const API_ADMIN_ROOT = '/admin'
const API_ARTICLE_ROOT = '/articles'
const API_AUTH_ROOT = '/auth'
const API_CHATROOMS_ROOT = '/chatrooms'
const API_CUSTOMER_EVENTS_ROOT = '/customer-events'
const API_IMAGES_ROOT = '/images'
const API_SUPPLIER_ROOT = '/supplier'
const API_SUPPLIERS_ROOT = '/suppliers'
const API_USERS_ROOT = '/users'

export const API_URL = {
  ADMIN: {
    ROOT: API_ADMIN_ROOT,
    REVIEWERS: API_ADMIN_ROOT + '/reviewers',
    SUPPLIER_APPLICATION: API_ADMIN_ROOT + '/suppliers/application',
    SUPPLIER: '/admin/supplier',
    IMAGES: API_ADMIN_ROOT + '/images',
    SUPPLIER_INVITE: API_ADMIN_ROOT + '/supplier/invite',
    CATEGORY_PAGE_DATA: API_ADMIN_ROOT + '/categories/page-data',
    CREATE_SUPPLIER_LINK: API_ADMIN_ROOT + '/suppliers/create-account-link',
    IMAGE: API_ADMIN_ROOT + '/images',
    SUPPLIERS_STATS: API_ADMIN_ROOT + '/suppliers/stats',
  },
  ARTICLES: {
    ROOT: API_ARTICLE_ROOT,
    PATH: API_ARTICLE_ROOT + '/path',
    CATEGORY_PAGE_DATA: API_ARTICLE_ROOT + '/categories/page-data',
    CATEGORIES: API_ARTICLE_ROOT + '/categories',
    SUBCATEGORIES: API_ARTICLE_ROOT + '/subcategories',
    GET_BY_ID: API_ARTICLE_ROOT + `/id`,
    PODCAST: API_ARTICLE_ROOT + '/podcast',
    RECOMMENDED: API_ARTICLE_ROOT + '/recommended',
    SEARCH: API_ARTICLE_ROOT + '/search',
  },
  AUTH: {
    LOGIN: API_AUTH_ROOT + '/login',
    LOGOUT: API_AUTH_ROOT + '/logout',
    REGISTER: API_AUTH_ROOT + '/register',
    FACEBOOK: API_AUTH_ROOT + '/facebook',
    GOOGLE: API_AUTH_ROOT + '/google',
    RESET_PASSWORD_EMAIL: API_AUTH_ROOT + '/password/reset-email',
    RESET_PASSWORD: API_AUTH_ROOT + '/password/reset',
  },
  BUSINESS_CATEGORIES: '/business-categories',
  CUSTOMER_EVENTS: {
    ROOT: API_CUSTOMER_EVENTS_ROOT,
    NUMBER_OF_GUESTS: API_CUSTOMER_EVENTS_ROOT + '/number-of-guests',
    IDEAL_BUDGET: API_CUSTOMER_EVENTS_ROOT + '/ideal-budget',
  },
  FAVOURITES: {
    ROOT: '/favourite',
  },
  LOCATIONS: {
    ROOT: '/locations', // @deprecated, use /regions
  },
  RECYCLES: {
    SEARCH: '/recycles',
    CATEGORIES: '/recycles/categories',
    CONTACT: '/api/recycle/contactRecycle',
    RECOMMENDED: '/recycles/recommended',
  },
  REGIONS: '/regions',
  SUPPLIERS: {
    ROOT: API_SUPPLIERS_ROOT,
    PAGINATION_SEARCH: API_SUPPLIERS_ROOT + '/pagination-search',
    SEARCH: API_SUPPLIERS_ROOT + '/search',
    GET_LISTING_BY_SLUG: API_SUPPLIERS_ROOT + '/article',
    SUGGESTED: API_SUPPLIERS_ROOT + '/suggested',
    SHUFFLED: API_SUPPLIERS_ROOT + '/shuffled',
  },
  SUPPLIER: {
    INTERACTION: API_SUPPLIER_ROOT + '/interaction',
    PRICE: API_SUPPLIER_ROOT + '/price',
    ARTICLE: API_SUPPLIER_ROOT + '/article',
  },
  TAG: {
    ROOT: '/tags',
  },
  UI_PLACEMENT: {
    ROOT: '/ui-placement',
  },
  USERS: {
    ROOT: API_USERS_ROOT,
    CURRENT_USER: API_USERS_ROOT + '/current-user',
    VERIFY_EMAIL: API_USERS_ROOT + '/verify-email',
    CHECK_EMAIL_EXIST: API_USERS_ROOT + '/check-email-exist',
  },
  MEMBERSHIP: {
    STATUS: '/membership/status',
  },
  REDIRECTS: {
    ROOT: '/redirect',
  },
  CHATROOMS: {
    ROOT: API_CHATROOMS_ROOT,
  },
  METADATA: {
    SITEMAP_LINKS: '/metadata/sitemaps',
    SITEMAP_IMAGES_LINKS: '/metadata/sitemaps/images',
    DYNAMIC_SITEMAP_INDEX: '/metadata/sitemap-index',
  },
}

export const NEXT_API_URL = {
  SUPPLIER: {
    INTERACTION: '/api' + API_SUPPLIER_ROOT + '/interaction',
  },
  ADMIN: {
    ARTICLES: '/api' + API_ARTICLE_ROOT + '/admin',
    IMAGES: '/api' + '/admin' + API_IMAGES_ROOT,
  },
  GET_TAGS: '/api/getTags',
  USERS: '/api/users',
  GET_IMAGE_LIBRARY: '/api/getImageLibrary',
  REVALIDATE: '/revalidate',
}

export const BLOCK_AREA = {
  MAIN: 'Main',
  OTHER_SUPPLIERS: 'Other suppliers',
}

export const ONE_MINUTE_IN_SECONDS = 60
export const ONE_HOUR_IN_SECONDS = 60 * 60
export const ONE_SECOND_IN_MILLISECONDS = 1000

export const PROD_URL = 'https://www.rockmywedding.co.uk'
export const PROD_DOMAIN = 'www.rockmywedding.co.uk'

export const GTM_TRACKING_IDS = {
  SUPPLIER_CLICK_THROUGHS: 'gtm-supplier-website-link',
  SUPPLIER_SHARE_CLICK: 'gtm-supplier-share-click',
}

export const GTM_EVENT = {
  ENQUIRY_SUBMIT: 'enquiry_submit',
  SUPPLIER_PROFILE_CLICK: 'supplier_profile_click',
  SIGN_UP: 'sign_up',
  ARTICLE_VIEW: 'article_view',
  SUPPLIER_PROFILE_VIEW: 'supplier_profile_view',
  NAV_CLICK: 'nav_click',
  CATEGORY_PAGE_VIEW: 'category_page_view',
}

export const LOCAL_STORAGE_KEYS = {
  GTM_EVENT: {
    PROVIDER_SIGN_UP_URL: 'ProviderAuthSignUpUrl',
  },
}

export const SLIDER_CLASS_NAME = 'keen-slider__slide'

export const SOCIAL_LINKS = {
  FACEBOOK: 'https://www.facebook.com/rockmywedding',
  INSTAGRAM: 'https://www.instagram.com/rockmywedding/',
  PINTEREST: 'https://www.pinterest.co.uk/rockmywedding/',
  TWITTER: 'https://twitter.com/RockMyWedding',
  TIKTOK: 'https://www.tiktok.com/@rockmywedding?lang=en',
}

export const FAVICON_URL = `${PROD_URL}/assets/icons/favicon/favicon.png`

export const SUBCATEGORY_PAGE_DATA = {
  ARTICLES_PER_PAGE: 21,
  ARTICLES_PER_PARAGRAPH: 7,
}

export const LAUNCH_DATE = '2024-08-08'

export const CATEGORY_PAGE = {
  viewAllArticlesName: 'All',
}

// hide temporary subcategories with less than 11 articles assigned
export const HIDDEN_SUBCATEGORIES = [
  'budget',
  'celebrity-weddings',
  'ceremony',
  'engagement-parties',
  'engagement-rings',
  'honeymoons',
  'makeup',
  'nails',
  'proposals',
  'proposals-abroad',
  'reception',
  'speeches',
  'stationery',
  'wedding-guest-fashion',
  'wedding-rings',
]

export const HIDDEN_CATEGORIES = ['/stag']
