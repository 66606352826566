'use client'

import Link from 'next/link'
import { Image } from '@/components/v2/atoms/image/image'
import s from './hero-section.module.css'
import { V2Hero } from '@/components/v2/molecules/hero/hero'
import { HeroInside } from '../../molecules/hero-inside/hero-inside'
import { PROD_URL } from '@/constants'
import cx from 'classnames'

type HeroSectionProps = {
  btnBgColor?: string
  btnColor?: string
  btnLinkText?: string
  btnLinkUrl?: string
  description: string
  imageAlt: string
  imageChipText?: string
  imageLinkUrl?: string
  imageSrc: string
  photographer?: {
    name: string
    url: string
  }
  title: string
  imgLoading?: 'lazy' | 'eager'
  contentInside?: boolean
}

export const HeroSection = ({
  btnBgColor,
  btnColor,
  btnLinkText,
  btnLinkUrl,
  description,
  imageAlt,
  imageChipText,
  imageLinkUrl,
  imageSrc,
  photographer,
  title,
  imgLoading,
  contentInside,
}: HeroSectionProps) => {
  const showButtonLink = btnLinkText && btnLinkUrl
  const content = (
    <div className={s.heroCard}>
      <h1 className={cx(s.heroCardHeader, s.heroCardV3Font)}>{title}</h1>
      <p className={s.heroCardText}>{description}</p>
      {showButtonLink && (
        <Link
          href={btnLinkUrl}
          style={{
            backgroundColor: btnBgColor,
            color: btnColor,
          }}
          className={s.linkButton}
        >
          <span>{btnLinkText}</span>
        </Link>
      )}
      {photographer && (
        <div className={s.heroPhotographerButtonWrapper}>
          <Image
            className={s.heroImageCamera}
            src={`${PROD_URL}/assets/images/camera.png`}
            alt="Camera"
            width={24}
            height={24}
            loading={imgLoading}
            fit
          />
          <span>Photography&nbsp;by&nbsp;</span>
          <a target="_blank" rel="noreferrer" href={photographer?.url}>
            {photographer?.name}
          </a>
        </div>
      )}
    </div>
  )

  if (contentInside) {
    return (
      <HeroInside
        imageSrc={imageSrc}
        imageAlt={imageAlt}
        imageChipText={imageChipText}
        content={content}
        heroImageRedirectUrl={imageLinkUrl}
        imageLoading={imgLoading}
      />
    )
  }

  return (
    <V2Hero
      imageSrc={imageSrc}
      imageAlt={imageAlt}
      imageChipText={imageChipText}
      content={content}
      heroImageRedirectUrl={imageLinkUrl}
      imageLoading={imgLoading}
    />
  )
}
