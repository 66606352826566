'use client'

import Link from 'next/link'
import { ROUTES, SLIDER_CLASS_NAME } from '@/constants'
import { RecycleCategory } from '@/types/Recycle'
import s from './recycle-category-slider.module.css'
import {
  Slider,
  carouselBreakpoints,
} from '@/components/v2/atoms/slider/slider'
import { Image } from '@/components/v2/atoms/image/image'

type Props = {
  recycleCategoryList: RecycleCategory[]
}

export const RecycleCategorySlider = (props: Props) => {
  const { recycleCategoryList } = props

  return recycleCategoryList.length > 0 ? (
    <div className={s.container}>
      <Slider
        options={{
          breakpoints: {
            '(min-width: 1024px)': {
              slides: {
                perView: 4,
                spacing: 10,
              },
            },
            [carouselBreakpoints.small]: {
              slides: {
                perView: 1.4,
                spacing: 10,
                origin: 'center',
              },
            },
          },
        }}
      >
        {recycleCategoryList.map((item) => {
          if (!item?.slug) return null
          const startBuyingUrl = `${ROUTES.RECYCLE.LIST}/${item?.slug}`
          return (
            <div key={item.id} className={SLIDER_CLASS_NAME}>
              <Link className={s.browseRecycleCard} href={startBuyingUrl}>
                <div className={s.browseRecycleImageContainer}>
                  <Image
                    className={s.browseRecycleImage}
                    src={item.image?.url}
                    alt={item.image?.alt}
                    width={275}
                    height={350}
                    fit
                  />
                </div>
                <div className={s.browseRecycleCardContent}>
                  <div className={s.browseRecycleCardTitle}>{item.name}</div>
                </div>
              </Link>
            </div>
          )
        })}
      </Slider>
    </div>
  ) : null
}
