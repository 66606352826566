'use client'

import React from 'react'
import { useModal } from '@/hooks/use-modal'
import { LoginModal } from '../login-modal/login-modal'
import { ModalBackground } from '../../molecules/modal-background/modal-background'
import { RegisterModal } from '../register-modal/register-modal'
import { ModalPane } from './modal-pane'
import {
  ModalContextType,
  ModalPositionType,
} from '@/context/modal-context/types'
import { ArticlesFiltersModal } from '../articles-filters-modal/articles-filters-modal'
import { UnpublishRecycleModal } from '../unpublish-recycle-modal/unpublish-recycle-modal'
import { BannerCardModal } from '../../molecules/banner-card-modal/banner-card-modal'
import { ConfirmationModal } from '../../molecules/confirmation-modal/confirmation-modal'
import { RealWeddingsModal } from '../../../v3/organisms/real-weddings-modal/real-weddings-modal'

const getModalContent = (
  modal: ModalContextType,
  position: ModalPositionType
) => {
  if (!modal.isOpen || modal.options.position !== position) {
    return null
  }

  switch (modal.name) {
    case 'login':
      return <LoginModal />
    case 'register':
      return <RegisterModal />
    case 'articles-filters':
      return <ArticlesFiltersModal />
    case 'unpublish-recycle':
      return <UnpublishRecycleModal />
    case 'banner-card':
      return <BannerCardModal />
    case 'confirmation':
      return <ConfirmationModal />
    case 'real-weddings-filters':
      return <RealWeddingsModal />
    default:
      return null
  }
}

const modalPositions = [
  'top',
  'right',
  'bottom',
  'left',
] as Array<ModalPositionType>

export const AuthModal: React.FC = () => {
  const modal = useModal()

  return (
    <>
      <ModalBackground />
      {modalPositions.map((position) => (
        <ModalPane key={position} position={position}>
          {getModalContent(modal, position)}
        </ModalPane>
      ))}
    </>
  )
}
