'use client'

import { useState } from 'react'
import { SocialAuthButton } from '../../molecules/social-auth-button/social-auth-button'
import { Transition } from '@headlessui/react'

import s from './register-modal.module.css'
import cx from 'classnames'
import { useFormik } from 'formik'
import { Input } from '../../atoms/input/input'
import { Button } from '../../atoms/button/button'
import { useModal } from '@/hooks/use-modal'
import { XModalButton } from '../../atoms/x-modal-button/x-modal-button'
import { api } from '@/api'
import { Checkbox } from '../../atoms/checkbox/checkbox'
import { GTM_EVENT, LOCAL_STORAGE_KEYS, PROD_URL, ROUTES } from '@/constants'
import Link from 'next/link'
import { Image } from '../../atoms/image/image'
import { useScrollLock } from 'usehooks-ts'
import { DatePickerButton } from '../../atoms/date-picker-button/date-picker-button'
import { Chevron } from '@/components/svg/chevron/chevron'
import * as Yup from 'yup'
import { useUser } from '@/hooks/use-user'
import { useRouter } from 'next/navigation'
import { sendGTMEvent } from '@next/third-parties/google'

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
})

type FormikValuesType = {
  email: string
  firstName: string
  lastName: string
  terms: boolean
  password: string
  weddingDate?: string
}

export const RegisterModal = () => {
  const modal = useModal()
  const user = useUser()
  const router = useRouter()
  const redirectUrl = modal.options.modalProps.redirectUrl
  useScrollLock()

  const [displayView, setDisplayView] = useState<'signup' | 'signup-email'>(
    'signup'
  )

  const onSubmit = async (values: FormikValuesType) => {
    let [err] = await api.fe.auth.register(
      {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        password: values.password,
        weddingDate: values.weddingDate,
        role: 'reader',
      },
      { queryParams: { redirectUrl } }
    )

    if (err) {
      formik.setErrors({ email: err.message })

      return
    }

    sendGTMEvent({
      event: GTM_EVENT.SIGN_UP,
      'page path': window.location.href,
    })
    await user.refreshUser()
    await modal.close()

    if (redirectUrl) {
      router.push(redirectUrl)
    }
  }

  const formik = useFormik<FormikValuesType>({
    onSubmit,
    initialValues: {
      email: modal.options.modalProps.email || '',
      firstName: modal.options.modalProps.firstName || '',
      lastName: modal.options.modalProps.lastName || '',
      password: '',
      terms: false,
    },
    validationSchema,
    validateOnChange: true,
  })

  const onXButtonClick = () => {
    modal.close()
  }

  const onLoginClick = async () => {
    await modal.close()
    await modal.open('login')
  }

  /**
   * this is not signup button, it takes you to the next part of the form
   * the real signup happens on formik.onSubmit
   */
  const onSignUpClick = async () => {
    if (!formik.values.terms) {
      formik.setErrors({ terms: 'You must accept the terms' })

      return
    }

    let [verifyEmailError] = await api.fe.user.verifyEmail(formik.values.email)

    if (verifyEmailError) {
      formik.setErrors({ email: verifyEmailError.message })

      return
    }

    await formik.validateField('email')
    if (!formik.isValid) {
      return
    }

    setDisplayView('signup-email')
  }

  return (
    <div className={cx(s.wrapper)}>
      <form className={s.form} onSubmit={formik.handleSubmit}>
        <Transition
          show={displayView === 'signup'}
          enter={s.signupEmailEnter}
          leave={s.signupEmailEnter}
          enterFrom={s.signupEnterFrom}
          enterTo={s.signupEnterTo}
          leaveFrom={s.signupLeaveFrom}
          leaveTo={s.signupLeaveTo}
        >
          <div className={cx(s.background)}>
            <h1 className={cx(s.header)}>
              Sign up to <br />
              Rock My Wedding
            </h1>
            <p className={cx(s.paragraph)}>
              Save articles and suppliers, see personalised content, send direct
              messages to suppliers and venues you like and more.
            </p>
            <div
              onClick={() => {
                localStorage.setItem(
                  LOCAL_STORAGE_KEYS.GTM_EVENT.PROVIDER_SIGN_UP_URL,
                  window.location.href
                )
              }}
            >
              <SocialAuthButton
                type="facebook"
                redirectUrl={redirectUrl || window.location.href}
              />
              <SocialAuthButton
                type="google"
                redirectUrl={redirectUrl || window.location.href}
              />
            </div>
            <div className={cx(s.orContainer)}>
              <div className={cx(s.line)}></div>
              <span className={cx(s.orText)}>OR</span>
              <div className={cx(s.line)}></div>
            </div>
            <label className={s.label} htmlFor="email">
              * Your email address
            </label>
            <Input
              id="email"
              name="email"
              type="email"
              required
              onChange={formik.handleChange}
              value={formik.values.email}
              className={cx(s.input, s.inputExtraPadding)}
            />
            {formik.errors.email && (
              <span className={s.error}>{formik.errors.email}</span>
            )}
            <Button
              className={s.submitButton}
              type="button"
              onClick={onSignUpClick}
              black
              disabled={formik.isSubmitting}
            >
              <Image
                src={`${PROD_URL}/assets/icons/mail.png`}
                alt="mail"
                width={24}
                height={24}
                fit
              />{' '}
              sign up with email
            </Button>
            {formik.errors.terms && (
              <>
                <br />
                <span className={cx(s.error, s.termsError)}>
                  {formik.errors.terms}
                </span>
              </>
            )}
            <div className={s.checkboxWrapper}>
              <Checkbox
                className={s.checkbox}
                label=""
                checked={formik.values.terms}
                onChange={() => {
                  formik.setFieldValue('terms', !formik.values.terms)
                }}
              ></Checkbox>
              <div>
                <span>
                  By creating your account, you accept the Rock My Wedding’s
                  &nbsp;
                </span>
                <Link href={`${ROUTES.TERMS}`} target="_blank">
                  <b>Terms & Conditions</b>.
                </Link>
              </div>
            </div>
          </div>
        </Transition>
        <Transition
          show={displayView === 'signup-email'}
          enter={s.signupEmailEnter}
          enterFrom={s.signupEmailEnterFrom}
          enterTo={s.signupEmailEnterTo}
          leaveFrom={s.signupEmailLeaveFrom}
          leaveTo={s.signupEmailLeaveTo}
          leave={s.signupEmailEnter}
        >
          <div className={cx(s.background, s.signupBackground)}>
            <h1 className={cx(s.header)}>Sign up with email</h1>

            <label className={s.label} htmlFor="email">
              * Your first name
            </label>
            <Input
              id="firstName"
              name="firstName"
              type="text"
              required
              onChange={formik.handleChange}
              value={formik.values.firstName}
              className={s.input}
            />
            <label className={s.label} htmlFor="email">
              * Your surname
            </label>
            <Input
              id="lastName"
              name="lastName"
              type="text"
              required
              onChange={formik.handleChange}
              value={formik.values.lastName}
              className={s.input}
            />
            <label className={s.label} htmlFor="email">
              * Password
            </label>
            <Input
              id="password"
              name="password"
              type="password"
              pattern="(?=.*\d)(?=.*[a-z]).{8,}"
              required
              onChange={formik.handleChange}
              value={formik.values.password}
              className={s.input}
              autoComplete="off"
            />
            <span className={s.inputDescription}>
              Enter a combination of at least 8 numbers and letters.
            </span>
            <span className={cx(s.label, s.datePickerDescription)}>
              When is your wedding date? (if you know)
            </span>
            <DatePickerButton
              value={formik.values.weddingDate}
              placeholderText="Select date"
              onChange={(date) => formik.setFieldValue('weddingDate', date)}
              buttonProps={{ className: s.datePickerButton }}
            />
            <br />
            <Button
              className={cx(s.submitButton)}
              type="submit"
              black
              disabled={formik.isSubmitting}
            >
              finish sign up
            </Button>
            <button
              type="button"
              onClick={() => setDisplayView('signup')}
              className={s.backButton}
            >
              <Chevron />
            </button>
          </div>
        </Transition>
      </form>
      <XModalButton onClick={onXButtonClick} className={cx(s.xButton)} />
      <div className={s.footer}>
        Already have an account? <button onClick={onLoginClick}>Log in</button>
      </div>
    </div>
  )
}
