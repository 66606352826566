'use client'

import React, {
  createContext,
  useState,
  ReactNode,
  useCallback,
  useEffect,
} from 'react'
import { getCookie, setCookie } from 'cookies-next'

export type FeatureFlag = 'articleContent' | 'homePage'

type FeatureFlagsState = Record<FeatureFlag, boolean>

type FeatureFlagContextType = {
  enable: (flags?: Partial<FeatureFlagsState>) => void
  disable: (flags?: Partial<FeatureFlagsState>) => void
  isEnabled: (flags: FeatureFlag | FeatureFlag[]) => boolean
}

export const FeatureFlagContext = createContext<
  FeatureFlagContextType | undefined
>(undefined)

const defaultOptions: FeatureFlagsState = {
  articleContent: false,
  homePage: false,
}

const COOKIE_KEY = `feature_flag`

export const FeatureFlagProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [options, setOptions] = useState<FeatureFlagsState>(defaultOptions)

  const getFlagsFromCookies = (): FeatureFlag[] => {
    const cookie = getCookie(COOKIE_KEY) as string | undefined
    return cookie ? (cookie.split(',') as FeatureFlag[]) : []
  }

  useEffect(() => {
    const cookieFlags = getFlagsFromCookies()
    setOptions((prev) =>
      Object.keys(prev).reduce((acc, key) => {
        acc[key as FeatureFlag] = cookieFlags.includes(key as FeatureFlag)
        return acc
      }, {} as FeatureFlagsState)
    )
  }, [])

  const updateFlagsInCookies = (updatedOptions: FeatureFlagsState) => {
    const activeFlags = Object.entries(updatedOptions)
      .filter(([, value]) => value)
      .map(([key]) => key as FeatureFlag)
    setCookie(COOKIE_KEY, activeFlags.join(','), { maxAge: 60 * 60 * 24 * 365 })
  }

  const enable = useCallback((flags?: Partial<FeatureFlagsState>) => {
    setOptions((prev) => {
      const updated = {
        ...prev,
        ...Object.keys(defaultOptions).reduce((acc, key) => {
          acc[key as FeatureFlag] = flags?.[key as FeatureFlag] ?? true
          return acc
        }, {} as FeatureFlagsState),
      }
      updateFlagsInCookies(updated)
      return updated
    })
  }, [])

  const disable = useCallback((flags?: Partial<FeatureFlagsState>) => {
    setOptions((prev) => {
      const updated = {
        ...prev,
        ...Object.keys(defaultOptions).reduce((acc, key) => {
          acc[key as FeatureFlag] = flags?.[key as FeatureFlag] ?? false
          return acc
        }, {} as FeatureFlagsState),
      }
      updateFlagsInCookies(updated)
      return updated
    })
  }, [])

  const isEnabled = useCallback(
    (flags: FeatureFlag | FeatureFlag[]) => {
      if (typeof flags === 'string') {
        return options[flags]
      }
      return flags.every((flag) => options[flag])
    },
    [options]
  )

  useEffect(() => {
    ;(window as any).__rmwFeatureFlag = {
      enable,
      disable,
      isEnabled,
    }
  }, [enable, disable, isEnabled])

  return (
    <FeatureFlagContext.Provider
      value={{
        enable,
        disable,
        isEnabled,
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  )
}
