'use client'

import { useEffect, useState } from 'react'
import { Select } from '../../../v3/atoms/select/select'
import s from './real-weddings-filters.module.css'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { ensureQueryArray } from '@/utils/ensureQueryArray'
import qs from 'qs'
import { Tag } from '@/types/Tag'

type Props = {
  tags: Tag[]
}

export const RealWeddingsFilters = ({ tags }: Props) => {
  const [filters, setFilters] = useState<number[]>([])
  const query = useSearchParams()
  const router = useRouter()
  const pathName = usePathname()

  const groupedFilters = tags.reduce(
    (acc, filter) => {
      if (!acc[filter.category_label]) {
        acc[filter.category_label] = []
      }
      acc[filter.category_label].push(filter)
      return acc
    },
    {} as { [key: string]: typeof tags }
  )

  const handleApplyFilters = () => {
    const currentParams = new URLSearchParams(query!.toString())
    const currentPage = Number(currentParams.get('page') || '1')

    if (filters.length === 0) {
      router.push(
        `${pathName}?${qs.stringify({
          page: currentPage,
        })}`,
        { scroll: false }
      )
      return
    }

    router.push(
      `${pathName}?${qs.stringify(
        { tag: filters },
        { indices: false, skipNulls: true }
      )}`,
      { scroll: false }
    )
  }

  const handleSelectFilter = (filter: number) => {
    if (filters.includes(filter)) {
      setFilters(filters.filter((f) => f !== filter))
    } else {
      let filterToRemove: number | undefined
      Object.keys(groupedFilters).forEach((key) => {
        if (groupedFilters[key].some((f) => f.id === filter)) {
          filterToRemove = groupedFilters[key].find((f) =>
            filters.includes(f.id)
          )?.id
        }
      })

      if (filterToRemove) {
        setFilters([...filters.filter((f) => f !== filterToRemove), filter])
        return
      }

      setFilters([...filters, filter])
    }
  }

  const handleRemoveFilter = (filter: number) => {
    setFilters(filters.filter((f) => f !== filter))
  }

  useEffect(() => {
    handleApplyFilters()
  }, [filters])

  useEffect(() => {
    const queryParamTags = ensureQueryArray(query?.getAll('tag'))
    setFilters(queryParamTags.map((t) => parseInt(t)))
  }, [])

  return (
    <div className={s.container}>
      {Object.values(groupedFilters).map((group) => {
        return (
          <div key={group[0].category_label} className={s.wrapper}>
            <Select
              className={s.select}
              label={group[0].category_label}
              options={group.map((g) => ({ label: g.name, value: g.id }))}
              value={filters.find((f) => group.some((g) => g.id === f)) || 0}
              onChange={handleSelectFilter}
              onRemove={handleRemoveFilter}
            />
          </div>
        )
      })}
    </div>
  )
}
