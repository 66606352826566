'use client'

import { PROD_URL } from '@/constants'
import {
  ColumnWidgetWrapper,
  GridItem,
  GridWidget,
  ImageWrapper,
  Title,
} from './styles/column.style'
import { Image } from '@/components/v2/atoms/image/image'

export interface ColumnBlockType {
  id: string
  type: 'column'
  content: string
  title?: string
  image: {
    name: string
    url: string
    alt: string
  }
}

const parseContent = (content: string = '') => {
  return content
    .replace(/src="{CCM:BASE_URL}/g, `src="${PROD_URL}`)
    .replace(/{CCM:BASE_URL}/g, process.env.NEXT_PUBLIC_BASE_URL!)
}

const Columns = (props: { content: ColumnBlockType[] }) => {
  const renderContent = props.content || []
  return (
    <GridWidget>
      {renderContent.map((column: ColumnBlockType) => {
        // during the migration centered tag was broken
        const contentCentered = column.content.includes('</center>')
        return (
          <ColumnWidgetWrapper key={column.id}>
            <Title
              dangerouslySetInnerHTML={{
                __html: parseContent(column.title),
              }}
            />
            {!!column?.image?.url && (
              <ImageWrapper>
                <Image
                  alt={column.image.alt}
                  src={column.image.url}
                  width={350}
                  height={500}
                />
              </ImageWrapper>
            )}
            <GridItem
              centered={contentCentered}
              isEmpty={!props.content}
              dangerouslySetInnerHTML={{
                __html: parseContent(column?.content),
              }}
            />
          </ColumnWidgetWrapper>
        )
      })}
    </GridWidget>
  )
}

export default Columns
