'use client'

import { SearchIconNew } from '@/components/svg/search-icon-new/search-icon-new'
import s from './nav-search.module.css'
import { useRouter } from 'next/navigation'
import { FormEvent, useRef, useState } from 'react'
import cx from 'classnames'
import { ROUTES } from '@/constants'
import { useOnClickOutside } from 'usehooks-ts'

type Props = {
  onSubmit?: () => void
}

export const NavSearch = ({ onSubmit }: Props) => {
  const [searchValue, setSearchValue] = useState('')
  const [isFocused, setIsFocused] = useState(false)
  const router = useRouter()
  const inputRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  useOnClickOutside(containerRef, () => {
    if (!searchValue) setIsFocused(false)
  })

  const handleSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    router.push(`${ROUTES.SEARCH}?query=${searchValue}`)
    onSubmit?.()
  }

  const handleContainerClick = () => {
    if (!searchValue) {
      setIsFocused(true)
      inputRef.current?.focus()
    }
  }

  return (
    <div
      ref={containerRef}
      className={s.container}
      onClick={handleContainerClick}
    >
      <form className={s.form} onSubmit={handleSearch}>
        <input
          type="text"
          placeholder="Search"
          className={cx(s.input, { [s.active]: searchValue })}
          value={searchValue}
          ref={inputRef}
          onChange={(e) => setSearchValue(e.currentTarget.value)}
        />
        <button
          type="submit"
          className={cx(s.submit, [
            {
              [s.transformSubmit]: isFocused || searchValue,
            },
          ])}
        >
          <SearchIconNew
            svgProps={{
              className: s.icon,
              'aria-label': 'Search',
            }}
          />
        </button>
      </form>
    </div>
  )
}
