'use client'

import { Fragment } from 'react'
import {
  QuoteBody,
  QuoteAuthor,
  Blockquote,
  QuoteBodyWithBackground,
  QuoteTitle,
  QuoteContent,
  QuotesImageContainer,
} from './styles/quote.style'
import { PROD_URL } from '@/constants'
import { Image } from '@/components/v2/atoms/image/image'

export interface QuoteBlockType {
  quote: string
  author: string
  template?: string
  title?: string
}

const Quote = (props: { content: QuoteBlockType }) => {
  const {
    content: { template = 'default', title = '' },
  } = props

  const withTemplate: any = {
    default: (
      <Fragment>
        {title && <QuoteTitle isTitle>{title}</QuoteTitle>}
        <QuotesImageContainer>
          <Image
            src={`${PROD_URL}/assets/icons/double-quote-up.png`}
            alt={'Quote Up'}
            width={36}
            height={27}
          />
          <Image
            src={`${PROD_URL}/assets/icons/double-quote-down.png`}
            alt={'Quote Down'}
            width={35}
            height={25}
          />
        </QuotesImageContainer>
        <QuoteBody
          isEmpty={!props.content}
          dangerouslySetInnerHTML={{
            __html: props.content.quote || 'Empty...',
          }}
        />
        <QuoteAuthor>{props.content.author}</QuoteAuthor>
      </Fragment>
    ),
    'with-background': (
      <Fragment>
        <QuoteBodyWithBackground isEmpty={!props.content}>
          {title && <QuoteTitle>{title}</QuoteTitle>}
          <QuoteContent
            dangerouslySetInnerHTML={{
              __html: props.content.quote || 'Empty...',
            }}
          />
          <QuoteAuthor isDefault={false}>{props.content.author}</QuoteAuthor>
        </QuoteBodyWithBackground>
      </Fragment>
    ),
  }

  return <Blockquote>{withTemplate[template]}</Blockquote>
}

export default Quote
