'use client'

import * as React from 'react'
import { ModalProvider } from '@/context/modal-context/modal-context'
import { MessagesProvider } from '@/context/messages-context/messages-context'
import { FeatureFlagProvider } from '@/context/feature-flag-context/feature-flag-context'

type Props = {
  children: React.ReactNode
}

export const Providers: React.FC<Props> = ({ children }) => {
  return (
    <FeatureFlagProvider>
      <MessagesProvider>
        <ModalProvider>{children}</ModalProvider>
      </MessagesProvider>
    </FeatureFlagProvider>
  )
}
