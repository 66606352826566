'use client'

import s from './tile.module.css'
import cx from 'classnames'
import { Image, ImageLoadingType } from '@/components/v2/atoms/image/image'
import { SLIDER_CLASS_NAME } from '@/constants'
import Link from 'next/link'

type Props = {
  imageProps: {
    src: string
    alt: string
    loading?: ImageLoadingType
  }
  children: React.ReactNode
  href?: string
  containerClassName?: string
  adjustToSlider?: boolean
  withGradient?: boolean
}

export const Tile = ({
  imageProps,
  children,
  href,
  containerClassName,
  adjustToSlider,
  withGradient,
}: Props) => {
  return (
    <TileWrapper
      href={href}
      className={containerClassName}
      adjustToSlider={adjustToSlider}
    >
      <div className={s.imageContainer}>
        <Image
          className={s.image}
          src={imageProps.src}
          alt={imageProps.alt}
          width={400}
          height={600}
          loading={imageProps.loading}
        />
        {withGradient && <div className={s.shadowGradient} />}
      </div>
      {children}
    </TileWrapper>
  )
}

type TileWrapperProps = {
  children: React.ReactNode
  href: Props['href']
  className: Props['containerClassName']
  adjustToSlider: Props['adjustToSlider']
}

const TileWrapper = ({
  children,
  href,
  className,
  adjustToSlider,
}: TileWrapperProps) => {
  if (!href) {
    return (
      <div
        role="article"
        className={cx(
          { [SLIDER_CLASS_NAME]: adjustToSlider },
          s.container,
          className
        )}
      >
        {children}
      </div>
    )
  }

  return (
    <Link
      href={href}
      className={cx(
        { [SLIDER_CLASS_NAME]: adjustToSlider, [s.cursorPointer]: href },
        s.container,
        className
      )}
    >
      {children}
    </Link>
  )
}
