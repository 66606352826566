'use client'

import { useMemo } from 'react'
import s from './location-select.module.css'
import cx from 'classnames'
import { ApiCountry } from '@/types/api/countries'
import {
  getFlatLocations,
  getInternationalCountries,
  getRegions,
} from '@/utils/locations'
import { DropdownDownIcon } from '@/components/svg/dropdown-down-icon/dropdown-down-icon'
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from '@headlessui/react'

interface LocationSelectProps {
  noValueButtonLabel?: string
  value?: { value: number; type: string } | null
  className?: string
  onChange: (value: LocationOption) => void
  countries?: ApiCountry[]
}

export interface LocationOption {
  label: string
  value: number
  type: LocationType
}

type LocationType = 'region' | 'location' | 'country'

function selectOptions(countries: ApiCountry[]) {
  const _flatLocationsInternational: LocationOption[] = []
  const _flatUkLocations: LocationOption[][] = []

  for (const country of countries) {
    if (country.name === 'International') {
      for (const region of country.regions) {
        for (const location of region.locations) {
          _flatLocationsInternational.push({
            label: location.name,
            value: location.id,
            type: 'country',
          })
        }
      }

      continue
    }

    for (const region of country.regions) {
      if (!_flatUkLocations[region.display_column]) {
        _flatUkLocations[region.display_column] = []
      }
      _flatUkLocations[region.display_column].push({
        label: region.name,
        value: region.id,
        type: 'region',
      })
      for (const location of region.locations) {
        _flatUkLocations[region.display_column].push({
          label: location.name,
          value: location.id,
          type: 'location',
        })
      }
    }
  }

  return {
    flatLocationsInternational: _flatLocationsInternational,
    flatUkLocations: _flatUkLocations,
  }
}

export const V2LocationSelect = (props: LocationSelectProps) => {
  const { noValueButtonLabel, value, onChange, countries } = props
  const { flatLocationsInternational, flatUkLocations } = useMemo(() => {
    return selectOptions(countries || [])
  }, [countries])

  const selectLocation = (locationOption: LocationOption) => {
    onChange(locationOption)
  }

  if (!countries?.length) return null

  // can't useMemo here, because value = 1 can be for region, location or country
  const selectedLabel = (() => {
    let label = '' as string | undefined

    const _regions = getRegions(countries)
    const _countries = [...countries, ...getInternationalCountries(countries)]
    const _locations = getFlatLocations(countries)

    switch (value?.type) {
      case 'country':
        label = _countries.find(
          (con: { id: number }) => con.id === Number(value.value)
        )?.name
        break
      case 'region':
        label = _regions.find((reg) => reg.id === Number(value.value))?.name
        break
      case 'location':
        label = _locations.find((loc) => loc.id === Number(value.value))?.name
        break
    }

    return label
  })()

  return (
    <div className={s.wrapper}>
      <Menu>
        <MenuButton className={s.button}>
          {selectedLabel || noValueButtonLabel || 'All Locations'}
          <DropdownDownIcon />
        </MenuButton>
        <MenuItems className={s.menuItems}>
          <TabGroup>
            <TabList className={s.tabList}>
              <Tab
                className={({ selected }) =>
                  cx(s.tabButton, {
                    [s.tabButtonActive]: selected,
                  })
                }
              >
                Counties
              </Tab>
              <Tab
                className={({ selected }) =>
                  cx(s.tabButton, s.secondTabButtton, {
                    [s.tabButtonActive]: selected,
                  })
                }
              >
                International
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <div className={s.optionsContainer}>
                  <div className={s.optionWrapper}>
                    {flatUkLocations[0].map((location) => (
                      <MenuItem
                        className={cx(s.styledItem, {
                          [s.bold]: location.type === 'region',
                        })}
                        as="button"
                        onClick={() => selectLocation(location)}
                        key={location.label}
                      >
                        {location.label}
                      </MenuItem>
                    ))}
                  </div>
                  {flatUkLocations.slice(1).map((locations, index) => (
                    <div key={index}>
                      {locations.map((location) => (
                        <MenuItem
                          className={cx(s.styledItem, {
                            [s.bold]: location.type === 'region',
                          })}
                          as="button"
                          onClick={() => selectLocation(location)}
                          key={location.label}
                        >
                          {location.label}
                        </MenuItem>
                      ))}
                    </div>
                  ))}
                </div>
              </TabPanel>
              <TabPanel>
                <div className={s.optionsContainer}>
                  <div className={s.optionWrapper}>
                    {flatLocationsInternational
                      .sort((a, b) => a.label.localeCompare(b.label))
                      .map((location) => (
                        <MenuItem
                          as="button"
                          className={cx(s.styledItem)}
                          onClick={() => selectLocation(location)}
                          key={location.label}
                        >
                          {location.label}
                        </MenuItem>
                      ))}
                  </div>
                </div>
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </MenuItems>
      </Menu>
    </div>
  )
}
