import { ApiHandlerResult } from '..'
import { NEXT_API_URL } from '@/constants'
import { BaseApi } from '../base'
import { Fetcher } from '@/api/fetcher'

export class RevalidateApi extends BaseApi {
  constructor(fetcher: Fetcher) {
    super(fetcher)
  }

  async revalidatePath(url: string): ApiHandlerResult<null> {
    try {
      const res = await this.fetcher.put(NEXT_API_URL.REVALIDATE, {
        body: JSON.stringify({
          url,
        }),
        credentials: 'include',
      })

      return await this.parseBodyOrThrow(res)
    } catch (error: any) {
      return [error, null]
    }
  }
}
