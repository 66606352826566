import { Slider } from '@/components/v2/atoms/slider/slider'
import Card, { CardType } from '@/components/v3/atoms/card/card'
import s from './slider-with-navigation.module.css'
import { ArrowRight } from '@/components/svg/arrow-right/arrow-right'
import cx from 'classnames'
import React from 'react'
import { KeenSliderOptions } from 'keen-slider'

interface Props {
  onItemClick?: () => void
  items: CardItem[]
  cardType: CardType
  navigationPosition?: 'right' | 'left'
  title: React.ReactNode
  keenSliderOptions?: KeenSliderOptions
  fullWidth?: boolean
}

export type CardItem = {
  id: number | string
  title: string
  date?: string
  imageUrl?: string
  imageAlt?: string
  category?: string
  location?: string
  path: string
}

export const SliderWithNavigation = ({
  onItemClick,
  items,
  cardType,
  navigationPosition = 'right',
  title,
  keenSliderOptions,
  fullWidth,
}: Props) => {
  if (!items?.length) {
    return null
  }

  return (
    <Slider
      ignoreDefaultBreakpoints
      options={{
        initial: 0,
        slides: {
          spacing: 24,
          perView: 'auto',
        },
        breakpoints: {},
        ...keenSliderOptions,
      }}
      renderNavigation={(slideLeft, slideRight) => {
        const navigationVisible = items.length > 3
        return (
          <div
            className={cx(s.topWrapper, {
              [s.navigationToLeft]: navigationPosition === 'left',
              [s.fullWidth]: fullWidth,
            })}
          >
            {title}
            {navigationVisible && (
              <div className={s.sliderControls}>
                <button onClick={slideLeft} className={s.sliderArrow}>
                  <ArrowRight
                    iconColor="#fff"
                    svgProps={{
                      style: {
                        transform: 'rotate(180deg)',
                      },
                    }}
                  />
                </button>
                <button onClick={slideRight} className={s.sliderArrow}>
                  <ArrowRight iconColor="#fff" />
                </button>
              </div>
            )}
          </div>
        )
      }}
    >
      {items?.map((item) => {
        return (
          <div key={`${item.id}-nav-slider`} onClick={() => onItemClick?.()}>
            <Card
              primaryColor="#fff"
              reverseVertical
              size="Small"
              adjustToSlider
              itemId={item.id!}
              header={item.title}
              path={item.path}
              date={item.date}
              category={item?.category || 'Category'}
              location={item?.location || 'Location'}
              type={cardType}
              imageUrl={item.imageUrl!}
              imageAlt={item.imageAlt!}
            />
          </div>
        )
      })}
    </Slider>
  )
}
