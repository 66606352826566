'use client'

import { PROD_URL, ROUTES } from '@/constants'
import { useUser } from '@/hooks/use-user'
import { HeroSection } from '@/components/v2/organisms/hero-section/hero-section'

const getDescription = (isReader: boolean, isMobile: boolean) => {
  if (isReader) {
    return `Based on your preferences, we've tailored the inspiration, advice, venues, and suppliers to suit your needs. Consider us your personal wedding BFF - there for you every step of the way!`
  }

  if (isMobile) {
    return 'Your wedding planning journey starts here.'
  }

  return 'Your wedding planning journey starts here. With wedding inspiration, ideas and advice, Rock My Wedding has the tools you need to make your wedding stress-free, from your engagement to your honeymoon.'
}

export const V2HeroSectionLandinPage = ({
  isMobile,
}: {
  isMobile: boolean
}) => {
  const { user, isReader } = useUser()
  const title = isReader ? `Hi ${user?.first_name}!` : 'Getting Hitched?'
  const description = getDescription(isReader, isMobile)

  return (
    <HeroSection
      title={title}
      description={description}
      btnBgColor={isReader ? '#DABBB7' : undefined}
      btnColor={isReader ? '#000000' : undefined}
      btnLinkUrl={ROUTES.WEDDING_PLANNING}
      imageAlt={'rock-my-wedding-bride-groom-getting-married-confetti-exit'}
      btnLinkText="START PLANNING"
      imageLinkUrl={ROUTES.WEDDING_PLANNING}
      imageSrc={`${PROD_URL}/assets/images/landing-page/rock-my-wedding-bride-groom-getting-married-confetti-exit.jpg`}
      imgLoading="eager"
      contentInside
    />
  )
}
