import { Slider } from '@/components/v2/atoms/slider/slider'
import { Article } from '@/types/Article'
import { V2ArticleTile } from '../../custom-tile/article-tile/article-tile'

type Props = {
  articles?: Article[]
}

export const ArticleSlider = ({ articles }: Props) => {
  if (!articles?.length) {
    return null
  }

  return (
    <Slider>
      {articles.map((article) => {
        return (
          <V2ArticleTile key={article.id} article={article} adjustToSlider />
        )
      })}
    </Slider>
  )
}
