'use client'

import Button from 'antd/lib/button'
import InputAnt from 'antd/lib/input'
import styled, { css } from 'styled-components'
import LockOutlined from '@ant-design/icons/LockOutlined'
import SyncOutlined from '@ant-design/icons/SyncOutlined'

const { TextArea: TextAreaAnt } = InputAnt

interface IInputProps {
  customStyle?: string
}

export const InputWrapper = styled.div<IInputProps>`
  margin-bottom: 1.8rem;
  position: relative;
`

interface ILabelProps {
  note?: boolean
  align?: string
  gap?: boolean
  capitalize?: boolean
  size?: number
  width?: number
  weight?: number
  ableSelect?: boolean
  error?: boolean
  bold?: boolean
}

export const Label = styled.label<ILabelProps>`
  ${({
    theme: {
      typography: { size, font },
      base: {
        grey,
        shade: { red },
      },
    },
    note = '',
    align,
    gap,
    size: fontSize,
    capitalize = false,
    width,
    weight,
    ableSelect,
    error,
  }: any) => `
    font-family: ${font.oxygen};
    user-select: ${ableSelect ? 'auto' : 'none'};
    ${capitalize ? `text-transform: capitalize;` : ''}
    white-space: pre-wrap;
    ${
      fontSize
        ? `
    font-size: ${fontSize}px;
    `
        : ''
    }
    ${
      gap
        ? `
      margin: 1rem auto;
      width: 90%;
    `
        : `margin-bottom: 10px;`
    }
    display: block;
    ${
      !!align &&
      `
      text-align: ${align};
    `
    }
    ${width ? `width: ${width}%` : ''};
    ${weight ? `font-weight: ${weight};` : ''}
    ${
      note &&
      `
      background-color: transparent;
      font-size: ${size.md}rem;
      margin: ${size.sm}rem 0;
      color: ${grey[11]};
      font-family: 'Oxygen';
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    `
    }
    ${error && `color: ${red[1]};`}
  `}
`

export const Bold = styled.b`
  text-decoration: underline;
`

export const PreloadContainer = styled.div<{ top?: number }>`
  ${({ top = '50%' }) => `
    position: absolute;
    width: fit-content;
    right: 10px;
    top: ${top};
    transform: translate(-50%, -50%);
  `}
`

export const LoadingInput = styled(SyncOutlined).attrs(() => ({
  spin: true,
}))``

export const Input = styled(InputAnt)<{ showCount?: boolean }>`
  ${({
    showCount,
    theme: {
      base: { grey },
    },
  }) => css`
    height: 50px;
    &:focus {
      border: 1px solid ${grey[21]} !important;
      box-shadow: unset;
    }
    &:hover {
      border: 1px solid ${grey[21]} !important;
    }

    .ant-input[disabled] {
      color: ${'#000000'};
    }
    ${showCount
      ? `
    .ant-input {
      height: 40px;
      border: unset !important;
      border-color:${grey[21]}!important;
      box-shadow: none !important;
    }

    border-color: ${grey[21]} !important;
    box-shadow: none !important;
    `
      : ''};
  `}
`

export const TextAreaWrapper = styled.div`
  padding: 1rem 0;
`

export const TextArea = styled(TextAreaAnt)`
  height: 70px;
`

export const ErrorMessage = styled.div<{
  toggle: Boolean
  breakLineBottom: Boolean
}>`
  ${({
    breakLineBottom,
    theme: {
      base: { shade },
    },
    toggle,
  }) => css`
    color: ${shade.red[0]};
    position: absolute;
    left: 0;
    transition: all 0.4s ease-in-out;
    opacity: ${toggle ? 1 : 0};
    ${breakLineBottom
      ? `
     transform-origin: top;
     transform: translate(10, 100%);
     line-height: 16px;
    `
      : `bottom: ${toggle ? '-23px' : '0px'};`}
  `}
`

export const ButtonStyle = styled(Button)`
  display: block;
  width: 40px;
  height: 40px;
  margin: 0 0.5rem;
`

export const ButtonGroup = styled.div`
  display: flex;
  margin: 1rem 0;
`

export const LockOutlinedWrapper = styled(LockOutlined)`
  font-size: 16px;
`

export default InputWrapper
