'use client'

import { Header } from '@/components/v3/organisms/navigation/header/header'
import { MainNavMobile } from '@/components/v3/organisms/navigation/main-nav-mobile/main-nav-mobile'
import { useState } from 'react'
import { useBreakpoint } from '@/hooks/use-breakpoint'
import { MainNav } from '@/components/v3/organisms/navigation/main-nav/main-nav'
import { Article } from '@/types/Article'
import {
  UiPlacement,
  UiPlacementPlacement,
  UiPlacementResponse,
  UiPlacementType,
} from '@/types/api/ui-placement'
import { Supplier } from '@/types/Supplier'
import { HIDDEN_CATEGORIES, HIDDEN_SUBCATEGORIES } from '@/constants'

export type MenuItemsType = {
  mainItem: UiPlacement
  subcategories: UiPlacement[]
}[]

function groupMenuItems(data: UiPlacementResponse['data']): MenuItemsType {
  const main = [
    ...data[UiPlacementType.ARTICLE_CATEGORY]?.[UiPlacementPlacement.MAIN_MENU],
    ...(data.metadata ? data.metadata[UiPlacementPlacement.MAIN_MENU] : []),
  ]
  const child = [
    ...data[UiPlacementType.ARTICLE_SUBCATEGORY]?.[
      UiPlacementPlacement.SUB_MENU
    ],
    ...data[UiPlacementType.BUSINESS_CATEGORY]?.[UiPlacementPlacement.SUB_MENU],
  ]

  const combinedItems = main
    .map((item) => {
      const subItems = child
        .filter(
          (subcategory) =>
            !HIDDEN_SUBCATEGORIES.includes(
              subcategory.article_subcategory_id!
            ) &&
            !HIDDEN_CATEGORIES.includes(subcategory.metadata?.overrides?.slug!)
        )
        .filter((subItem: UiPlacement) => {
          // this one is hardcoded because we don't have a way to connect these values on the be
          const isWeddingSuppliers = item.metadata?.slug === 'wedding-suppliers'

          if (isWeddingSuppliers) {
            return subItem.type === UiPlacementType.BUSINESS_CATEGORY
          }

          const isChildOfMainCategory =
            subItem.article_subcategories?.parent_slug ===
              item.article_category_id ||
            subItem.metadata?.overrides?.article_category_id ===
              item.article_category_id

          return isChildOfMainCategory
        })
        .toSorted((a, b) => a.sort_order - b.sort_order)

      return {
        mainItem: item,
        subcategories: subItems,
      }
    })
    .toSorted((a, b) => a.mainItem.sort_order - b.mainItem.sort_order)

  return combinedItems
}

type Props = {
  featuredArticles?: Article[]
  menuItems: UiPlacementResponse['data']
  navbarSuppliers?: Supplier[]
  navbarVenues?: Supplier[]
}

export const NavigationIndex = ({
  featuredArticles,
  menuItems,
  navbarSuppliers,
  navbarVenues,
}: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const breakpoint = useBreakpoint()
  const isDesktop = breakpoint?.includes('desktop')
  const groupedMenuItems = groupMenuItems(menuItems)

  return (
    <>
      <Header onHamburgerIconClick={() => setIsMenuOpen(true)} />
      {isDesktop ? (
        <MainNav
          featuredArticles={featuredArticles}
          navbarSuppliers={navbarSuppliers}
          navbarVenues={navbarVenues}
          menuItems={groupedMenuItems}
        />
      ) : (
        <MainNavMobile
          isMenuOpen={isMenuOpen}
          onOutsideNavClick={() => setIsMenuOpen(false)}
          menuItems={groupedMenuItems}
        />
      )}
    </>
  )
}
