import { ApiCountry, ApiLocation, ApiRegion } from '@/types/api/countries'
import { LocationOption } from '@/components/v2/molecules/custom-select/location-select/location-select'
import { LocationResponseKeys } from '@/v2/fetch/fetch-locations'

export const LOCATION_TYPE_DICT: Record<
  LocationResponseKeys,
  LocationOption['type']
> = Object.freeze({
  countries: 'location',
  internationalCountries: 'country',
  regions: 'region',
})

export const getRegions = (data: ApiCountry[]) => {
  return data.reduce((acc, current: { regions: any; name: string }) => {
    acc = [...acc, ...current.regions]
    return acc
  }, [] as ApiRegion[])
}

export const getInternationalCountries = (data: ApiCountry[]) => {
  return data.reduce((acc: any[], current: ApiCountry) => {
    if (current.name === 'International' && current.regions[0].locations) {
      acc = [...acc, ...current.regions[0].locations]
    }
    return acc
  }, []) as ApiCountry[]
}

export const getFlatLocations = (data: ApiCountry[]) => {
  return data.reduce((acc, country) => {
    const flatLocation = country.regions.reduce((acc, region) => {
      if (region.name !== 'International') {
        acc = [...acc, ...region.locations]
      }
      return acc
    }, [] as ApiLocation[])
    return (acc = [...acc, ...flatLocation])
  }, [] as ApiLocation[])
}

export const mapLocation = (
  location?: ApiCountry | ApiLocation | ApiRegion | null,
  type?: LocationOption['type']
): LocationOption | undefined => {
  if (!location || !type) {
    return undefined
  }

  return {
    value: location.id,
    label: location.name,
    type: type,
  }
}
