'use client'

import { Tag } from '@/types/Tag'
import s from './articles-filters.module.css'
import { SecondarySelect } from '@/components/v2/molecules/custom-select/secondary-select/secondary-select'
import { ClearCircleIcon2 } from '@/components/svg/clear-circle-icon-2/clear-circle-icon-2'
import { useArticleFilters } from './useArticleFilters'

type Props = {
  tagsToDisplay: Tag[]
}

export const ArticlesFilters = ({ tagsToDisplay }: Props) => {
  const { changeTagFilters, removeTagFilter, tags, selectedTags } =
    useArticleFilters({
      tagsToDisplay,
    })

  return (
    <div className={s.filtersContainer}>
      {Object.entries(tags).map(([label, tags], i) => {
        const selectedTag = tags.find(
          (tag) => selectedTags.map((tag) => tag.id)?.includes(tag.id)
        )

        return (
          <SecondarySelect<{
            value: number
            label: string
          }>
            key={i}
            label={
              selectedTag?.name ? (
                <span className={s.selectLabel}>
                  {selectedTag.name} <ClearCircleIcon2 fontSize={12} />
                </span>
              ) : (
                label
              )
            }
            options={tags.map((tag) => {
              return { label: tag.name, value: tag.id }
            })}
            className={s.filterSelect}
            onChange={changeTagFilters}
            onRemove={removeTagFilter}
            value={selectedTag?.id}
          />
        )
      })}
    </div>
  )
}
