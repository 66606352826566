import { API_URL } from '@/constants'
import { ApiHandlerResult } from '..'
import { BaseApi } from '../base'
import { RequestInit } from 'next/dist/server/web/spec-extension/request'
import { Fetcher } from '@/api/fetcher'
import { PaginationResponse } from '@/types/api/pagination-response'
import {
  ApiRecommendedSupplier,
  SortSuppliersType,
  Supplier,
} from '@/types/Supplier'
import shuffle from 'lodash/shuffle'
import { SupplierArticle } from '@/types/api/listing-view'

type GetSuppliersParamsType = {
  page?: number
  pageSize?: number
  tags?: (string | number)[] | string | number
  sort?: SortSuppliersType
  title?: string
  business_category?: number | number[]
  locationIds?: number | number[]
  region?: number
  country?: number
  includeInactiveSuppliers?: boolean
}

type GetShuffledSuppliersParamsType = {
  page?: number
  pageSize?: number
  tags?: (string | number)[] | string | number
  sort?: SortSuppliersType
  business_category?: number | number[]
  locationIds?: number | number[]
  region?: number
  country?: number
}

type GetRandomSuppliersParamsType = {
  pageSize?: number
  tags?: (string | number)[] | string | number
  title?: string
  business_category?: number | number[]
  locationIds?: number | number[]
  region?: number
  country?: number
}

type getRandomSuppliersOptionsType = {
  itemsQuantity?: number
  requestInit?: RequestInit
}

type GetSuppliersPriceParams = {
  businessCategoryId: number | string
}

export type GetPriceResponse = {
  price: number
  firstPaymentDate: string
  locations: string[]
}

export class SupplierApi extends BaseApi {
  constructor(fetcher: Fetcher) {
    super(fetcher)
  }

  async getRandom(
    params: GetRandomSuppliersParamsType,
    options?: getRandomSuppliersOptionsType
  ): ApiHandlerResult<PaginationResponse<Supplier>> {
    try {
      if (!params?.pageSize) params.pageSize = 100
      let itemsQuantity = 15
      if (options?.itemsQuantity) itemsQuantity = options.itemsQuantity
      if (itemsQuantity > params.pageSize) itemsQuantity = params.pageSize

      const res = await this.fetcher.get(API_URL.SUPPLIERS.PAGINATION_SEARCH, {
        params,
        ...options?.requestInit,
      })

      if (res.status !== 200) {
        const errBody = await res.json()
        console.log('>> error api.supplier.getRandom', errBody)
        return [new Error(errBody), null]
      }

      const body = (await res.json()) as PaginationResponse<Supplier>
      body.results = shuffle(body.results).slice(0, itemsQuantity)
      return [null, body]
    } catch (err: any) {
      console.log(`>> error api.supplier.getRandom`, err)
      return [err, null]
    }
  }

  async getSuggested(
    params?: { limit?: number },
    options?: RequestInit
  ): ApiHandlerResult<PaginationResponse<ApiRecommendedSupplier>> {
    try {
      const res = await this.fetcher.get(API_URL.SUPPLIERS.SUGGESTED, {
        params,
        ...options,
      })

      if (res.status !== 200) {
        const errBody = await res.json()
        console.log('>> error api.supplier.getSuggested', errBody)
        return [new Error(errBody), null]
      }

      const body = (await res.json()) as {
        suppliers: ApiRecommendedSupplier[]
        count: number
      }

      return [
        null,
        {
          pagination: {
            page: 1,
            pageSize: body.count,
            total: body.count + 1, // we don't have that information from response, so we use count + 1
          },
          results: body.suppliers,
        },
      ]
    } catch (err: any) {
      console.log(`>> error api.supplier.getSuggested`, err)
      return [err, null]
    }
  }

  async search(
    params?: GetSuppliersParamsType,
    options?: RequestInit
  ): ApiHandlerResult<PaginationResponse<Supplier>> {
    try {
      const res = await this.fetcher.get(API_URL.SUPPLIERS.PAGINATION_SEARCH, {
        params,
        ...options,
      })

      return await this.parseBodyOrThrow(res)
    } catch (err: any) {
      return [err, null]
    }
  }

  async getShuffled(
    params?: GetShuffledSuppliersParamsType,
    options?: RequestInit
  ): ApiHandlerResult<PaginationResponse<Supplier>> {
    try {
      const res = await this.fetcher.get(API_URL.SUPPLIERS.SHUFFLED, {
        params,
        ...options,
      })

      return await this.parseBodyOrThrow(res)
    } catch (err: any) {
      return [err, null]
    }
  }

  async getPrice(
    params?: GetSuppliersPriceParams,
    options?: RequestInit
  ): ApiHandlerResult<GetPriceResponse> {
    try {
      const res = await this.fetcher.get(API_URL.SUPPLIER.PRICE, {
        params,
        ...options,
      })

      if (res.status !== 200) {
        const errBody = await res.json()
        console.log('>> error api.supplier.getPrice', errBody)
        return [new Error(errBody), null]
      }

      const body = await res.json()
      return [null, body]
    } catch (err: any) {
      console.log(`>> error api.supplier.getPrice`, err)
      return [err, null]
    }
  }

  async getBySlug(
    params: { slug: string; type?: number; excludedType?: number },
    options?: RequestInit
  ): ApiHandlerResult<SupplierArticle> {
    try {
      const { slug, ...restParams } = params
      const res = await this.fetcher.get(
        `${API_URL.SUPPLIER.ARTICLE}/${slug}`,
        {
          params: restParams,
          ...options,
        }
      )

      if (res.status !== 200) {
        const errBody = await res.json()
        console.log('>> error api.supplier.getBySlug', errBody)
        return [new Error(errBody), null]
      }

      const body = await res.json()
      return [null, body]
    } catch (err: any) {
      console.log(`>> error api.supplier.getBySlug`, err)
      return [err, null]
    }
  }
}
