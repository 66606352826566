'use client'

import { PROD_URL, ROUTES } from '@/constants'
import CTACarousel, { CTACarouselItem } from '../../ui/ctaCarousel'

const config: CTACarouselItem[] = [
  {
    imageUrl: `${PROD_URL}/assets/images/landing-page/real-weddings.png`,
    imageAlt: 'real weddings',
    title: 'Real weddings',
    redirectUrl: ROUTES.WEDDING_INSPIRATION,
    size: 48,
  },
  {
    imageUrl: `${PROD_URL}/assets/images/landing-page/planning-large.png`,
    imageAlt: 'planning',
    title: 'Planning',
    redirectUrl: ROUTES.WEDDING_PLANNING,
    size: 48,
  },
  {
    imageUrl: `${PROD_URL}/assets/images/landing-page/readings.png`,
    imageAlt: 'readings',
    title: 'Readings',
    redirectUrl: ROUTES.WEDDING_PLANNING + '?tag=32',
    size: 48,
  },
  {
    imageUrl: `${PROD_URL}/assets/images/landing-page/gifts.png`,
    imageAlt: 'gift lists',
    title: 'Gift lists',
    redirectUrl: ROUTES.WEDDING_PLANNING + '?tag=22',
    size: 48,
  },
  {
    imageUrl: `${PROD_URL}/assets/images/landing-page/podcast.png`,
    imageAlt: 'podcast',
    title: 'Podcast',
    redirectUrl: ROUTES.PODCAST.ROOT,
    size: 48,
  },
]

const BottomCTA: React.FC = () => {
  return <CTACarousel items={config} />
}

export default BottomCTA
