/**
 * this file is managed by "yarn generate-error-code"
 * do not edit manually
 */

export enum ErrorCode {
  e00001 = 'e00001',
  e00002 = 'e00002',
  e00003 = 'e00003',
  e00004 = 'e00004',
  e00005 = 'e00005',
  e00006 = 'e00006',
  e00007 = 'e00007',
    e00008 = "e00008",
    e00009 = "e00009",
    e00010 = "e00010",
    e00011 = "e00011",
    e00012 = "e00012",
    e00013 = "e00013",
    e00014 = "e00014",
    e00015 = "e00015",
    e00016 = "e00016",
    e00017 = "e00017",
    e00018 = "e00018",
    e00019 = "e00019",
    e00020 = "e00020",
    e00021 = "e00021",
    e00022 = "e00022",
    e00023 = "e00023",
    e00024 = "e00024",
    e00025 = "e00025",
    e00026 = "e00026",
    e00027 = "e00027",
    e00028 = "e00028",
    e00029 = "e00029",
    e00030 = "e00030",
    e00031 = "e00031",
    e00032 = "e00032",
    e00033 = "e00033",
    e00034 = "e00034",
    e00035 = "e00035",
    e00036 = "e00036",
    e00037 = "e00037",
    e00038 = "e00038",
    e00039 = "e00039",
    e00040 = "e00040",
    e00041 = "e00041",
    e00042 = "e00042",
    e00043 = "e00043",
    e00044 = "e00044",
    e00045 = "e00045",
    e00046 = "e00046",
    e00047 = "e00047",
    e00048 = "e00048",
    e00049 = "e00049",
    e00050 = "e00050",
    e00051 = "e00051",
    e00052 = "e00052",
    e00053 = "e00053",
    e00054 = "e00054",
    e00055 = "e00055",
    e00056 = "e00056",
    e00057 = "e00057",
    e00058 = "e00058",
    e00059 = "e00059",
    e00060 = "e00060",
    e00061 = "e00061",
    e00062 = "e00062",
    e00063 = "e00063",
    e00064 = "e00064",
    e00065 = "e00065",
    e00066 = "e00066",
    e00067 = "e00067",
    e00068 = "e00068",
    e00069 = "e00069",
    e00070 = "e00070",
    e00071 = "e00071",
    e00072 = "e00072",
    e00073 = "e00073",
    e00074 = "e00074",
    e00075 = "e00075",
    e00076 = "e00076",
    e00077 = "e00077",
    e00078 = "e00078",
    e00079 = "e00079",
    e00080 = "e00080",
    e00081 = "e00081",
    e00082 = "e00082",
    e00083 = "e00083",
    e00084 = "e00084",
    e00085 = "e00085",
    e00086 = "e00086",
    e00087 = "e00087",
    e00088 = "e00088",
    e00089 = "e00089",
    e00090 = "e00090",
    e00091 = "e00091",
    e00092 = "e00092",
    e00093 = "e00093",
    e00094 = "e00094",
    e00095 = "e00095",
    e00096 = "e00096",
    e00097 = "e00097",
    e00098 = "e00098",
    e00099 = "e00099",
    e00100 = "e00100",
    e00101 = "e00101",
    e00102 = "e00102"
}
