import { useContext } from 'react'
import { MessagesContext } from '@/context/messages-context/messages-context'

export const useMessages = () => {
  const context = useContext(MessagesContext)
  if (context === undefined) {
    throw new Error(
      'RMW dev error: useUnreadMessages must be used within a UnreadMessagesProvider'
    )
  }

  return context
}
