'use client'

import { useMemo, useState } from 'react'

import s from './unpublish-recycle-modal.module.css'
import cx from 'classnames'
import { Button } from '../../atoms/button/button'
import { useModal } from '@/hooks/use-modal'
import { XModalButton } from '../../atoms/x-modal-button/x-modal-button'
import { useScrollLock } from 'usehooks-ts'
import { RecycleStatus } from '@/types/Recycle'
import { Select } from '@/components/v2/atoms/select/select'

const options = [
  { label: 'Sold on RMW', value: 1 },
  { label: 'Sold elsewhere', value: 2 },
  { label: 'No longer selling', value: 3 },
]

export const UnpublishRecycleModal = () => {
  const [value, setValue] = useState<number>()
  const modal = useModal()
  useScrollLock()

  const onXButtonClick = () => {
    modal.close()
  }

  const valueLabel = useMemo(() => {
    const selectedOption = options.find((o) => o.value === value)
    return selectedOption?.label
  }, [value])

  return (
    <div className={cx(s.wrapper)}>
      <div className={cx(s.background)}>
        <XModalButton onClick={onXButtonClick} className={cx(s.xButton)} />
        <h1 className={cx(s.header)}>Unpublish your item</h1>
        <p>
          We&apos;ll unpublish the item for you no problem. First, please let us
          know why you are unpublishing your item today:
        </p>
        <Select
          label={valueLabel || 'Reasons for unpublishing'}
          options={options}
          onChange={(value: number) => {
            setValue(value)
          }}
          value={value}
        />
        <Button
          disabled={!value}
          className={s.button}
          onClick={() => {
            modal.options.modalProps?.handleUpdateStatus(
              RecycleStatus.UNPUBLISHED,
              value
            )
            modal.close()
          }}
        >
          Confirm unpublish
        </Button>
      </div>
    </div>
  )
}
