import { API_URL } from '@/constants'
import { ApiHandlerResult } from '..'
import { BaseApi } from '../base'
import { RequestInit } from 'next/dist/server/web/spec-extension/request'
import { Fetcher } from '@/api/fetcher'
import {
  ArticleCategory,
  ArticleCategoryPageDataPayload,
  ArticleCategoryPageDataType,
} from '@/types/api/article-category'
import {
  ApiAdminSupplier,
  SupplierApplicationWithAccount,
} from '@/types/Supplier'
import { PaginationResponse } from '@/types/api/pagination-response'
import { Image } from '@/types/Image'
import { SupplierApplication } from '@/types/api/supplier-application'
import { SupplierStats } from '@/types/api/supplier-stats'

export type GetImagesOptionsType = {
  limit?: number
  page?: number
  search?: string
  type?: 'article' | 'recycle' | 'supplier'
}

export type UpdateCategoryPageDataType = {
  slug: string
  type: ArticleCategoryPageDataType
  categoryPageDataPayload: ArticleCategoryPageDataPayload
}

export type UpdateImagePayloadType = {
  id: number
  alt: string
}

export class AdminApi extends BaseApi {
  constructor(fetcher: Fetcher) {
    super(fetcher)
  }

  async getSupplierApplication(
    id: number | string,
    options?: RequestInit
  ): ApiHandlerResult<SupplierApplication> {
    try {
      const res = await this.fetcher.get(
        `${API_URL.ADMIN.SUPPLIER_APPLICATION}/${id}`,
        { ...options }
      )

      if (res.status !== 200) {
        const errBody = await res.json()
        console.log('>> api.admin.supplierApplication', errBody)
        return [new Error(errBody), null]
      }

      const body = await res.json()
      return [null, body]
    } catch (err: any) {
      console.log(`>> error api.admin.supplierApplication`, err)
      return [err, null]
    }
  }

  async getReviewers(
    options?: RequestInit
  ): ApiHandlerResult<ArticleCategory[]> {
    try {
      const res = await this.fetcher.get(
        `${API_URL.ADMIN.REVIEWERS}?no_limit=true`, // not sure what no_limit does
        { ...options }
      )

      if (res.status !== 200) {
        const errBody = await res.json()
        console.log('>> api.admin.getReviewers', errBody)
        return [new Error(errBody), null]
      }

      const body = await res.json()
      return [null, body]
    } catch (err: any) {
      console.log(`>> error api.admin.getReviewers`, err)
      return [err, null]
    }
  }

  async getAdminById(
    id: number | string,
    options?: RequestInit
  ): ApiHandlerResult<ArticleCategory[]> {
    try {
      const res = await this.fetcher.get(`${API_URL.ADMIN.REVIEWERS}/${id}`, {
        ...options,
      })

      if (res.status !== 200) {
        const errBody = await res.json()
        console.log('>> api.admin.getAdminById', errBody)
        return [new Error(errBody), null]
      }

      const body = await res.json()
      return [null, body]
    } catch (err: any) {
      console.log('>> error api.admin.getAdminById', err)
      return [err, null]
    }
  }

  async getSupplierById(
    id: string | number,
    options?: RequestInit
  ): ApiHandlerResult<ApiAdminSupplier> {
    try {
      const res = await this.fetcher.get(`${API_URL.ADMIN.SUPPLIER}/${id}`, {
        ...options,
      })

      if (res.status !== 200) {
        const errBody = await res.json()
        console.log('>> api.admin.getSupplierById', errBody)
        return [new Error(errBody), null]
      }

      const body = await res.json()
      return [null, body]
    } catch (err: any) {
      console.log('>> error api.admin.getSupplierById', err)
      return [err, null]
    }
  }

  async getImages(
    params?: GetImagesOptionsType,
    options?: RequestInit
  ): ApiHandlerResult<PaginationResponse<Image>> {
    try {
      const res = await this.fetcher.get(API_URL.ADMIN.IMAGES, {
        params,
        ...options,
      })

      if (res.status !== 200) {
        const errBody = await res.json()
        console.log('>> api.admin.getImages', errBody)
        return [new Error(errBody), null]
      }

      const body = await res.json()
      return [null, body]
    } catch (err: any) {
      console.log('>> error api.admin.getImages', err)
      return [err, null]
    }
  }

  async getSupplierApplicationWithAccount(
    params: {
      page?: number
      statuses?: string | string[]
      search?: string
      business_categories?: string | string[]
    },
    options?: RequestInit
  ): ApiHandlerResult<PaginationResponse<SupplierApplicationWithAccount>> {
    try {
      const res = await this.fetcher.get(API_URL.ADMIN.SUPPLIER_APPLICATION, {
        params,
        ...options,
      })

      return await this.parseBodyOrThrow(res)
    } catch (err: any) {
      return [err, null]
    }
  }

  async postSupplierInvite(
    body: {
      email: string
      business_name: string
      business_category: string
      message: string
    },
    options?: RequestInit
  ): ApiHandlerResult<any> {
    try {
      const res = await this.fetcher.post(API_URL.ADMIN.SUPPLIER_INVITE, {
        body: JSON.stringify(body),
        ...options,
      })

      return await this.parseBodyOrThrow(res)
    } catch (err: any) {
      return [err, null]
    }
  }

  async updateCategoryPageData(
    payload: UpdateCategoryPageDataType,
    options?: RequestInit
  ): ApiHandlerResult<null> {
    try {
      const { slug, categoryPageDataPayload, type } = payload
      const res = await this.fetcher.put(
        `${API_URL.ADMIN.CATEGORY_PAGE_DATA}/${slug}`,
        {
          body: JSON.stringify(categoryPageDataPayload),
          params: { type },
          ...options,
        }
      )

      if (res.status !== 204) {
        const errBody = await res.json()
        return [new Error(errBody), null]
      }
      return [null, null]
    } catch (err: any) {
      return [err, null]
    }
  }

  async getCreateSupplierLink(
    id: number,
    options?: RequestInit
  ): ApiHandlerResult<{ url: string }> {
    try {
      const res = await this.fetcher.get(
        `${API_URL.ADMIN.CREATE_SUPPLIER_LINK}/${id}`,
        {
          ...options,
        }
      )

      return await this.parseBodyOrThrow(res)
    } catch (err: any) {
      return [err, null]
    }
  }

  async updateImage(
    payload: UpdateImagePayloadType,
    options?: RequestInit
  ): ApiHandlerResult<null> {
    try {
      const { id, ...restImage } = payload
      const res = await this.fetcher.put(`${API_URL.ADMIN.IMAGE}/${id}`, {
        body: JSON.stringify(restImage),
        ...options,
      })

      if (res.status !== 204) {
        const errBody = await res.json()

        return [new Error(errBody), null]
      }

      return [null, null]
    } catch (err: any) {
      return [err, null]
    }
  }

  async getSuppliersStats(
    params: { suppliersIds?: number[]; startDate?: string; endDate?: string },
    options?: RequestInit
  ): ApiHandlerResult<SupplierStats[]> {
    try {
      const res = await this.fetcher.get(API_URL.ADMIN.SUPPLIERS_STATS, {
        params,
        ...options,
      })

      return await this.parseBodyOrThrow(res)
    } catch (err: any) {
      return [err, null]
    }
  }
}
