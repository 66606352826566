'use client'

import { api } from '@/api'
import { Slider } from '@/components/v2/atoms/slider/slider'
import { V2ArticleTile } from '@/components/v2/molecules/custom-tile/article-tile/article-tile'
import { ComponentProps, useEffect, useState } from 'react'
import { useUser } from '@/hooks/use-user'
import { Article } from '@/types/Article'

type FetchParams = {
  category?: string
  subcategory?: string
}

const fetchArticles = async (fetchParams: FetchParams) => {
  const [err, res] = await api.fe.article.search({
    ...fetchParams,
    sort: 'published_at_desc',
  })

  if (err) return []
  return res?.results || []
}

type Props = {
  sliderProps?: ComponentProps<typeof Slider>
  category?: string
  subcategory?: string
}

export const ArticleSlider = (props: Props) => {
  const { sliderProps, category, subcategory } = props
  const { isReader } = useUser()

  const [articles, setArticles] = useState<Article[]>([])

  useEffect(() => {
    if (!isReader) {
      return
    }

    fetchArticles({ category, subcategory }).then((articles) => {
      setArticles(articles)
    })
  }, [isReader])

  if (!articles?.length) return null

  return (
    <Slider {...sliderProps}>
      {articles?.map((a) => (
        <V2ArticleTile article={a} key={a.id} adjustToSlider />
      ))}
    </Slider>
  )
}
