'use client'

import { V2Hero } from '@/components/v2/molecules/hero/hero'
import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined'
import s from './heroCreateAccount.module.css'
import { PROD_URL } from '@/constants'
import { Button } from '@/components/v2/atoms/button/button'
import { useRegisterModal } from '@/hooks/use-register-modal'

const configItems = [
  'Keep track of your favourite real weddings and be inspired by articles and ideas that suit you.',
  'Access detailed checklists and emails designed by experts to enhance your planning journey. ',
  'Discover and message your dream wedding team from our Recommended supplier lists.',
  'Buy and sell pre-loved wedding dresses, decor, accessories and more with Recycle My Wedding.',
]

const HeroCreateAccount: React.FC = () => {
  const registerModal = useRegisterModal()

  const handleSignUpButton = () => {
    registerModal.open()
  }

  const content = (
    <div className={s.contentContainer}>
      <h4 className={s.heroCreateAccountTitle}>CREATE AN ACCOUNT</h4>
      <p className={s.heroCreateAccountSubTitle}>
        Your dream wedding day is just a click away...
      </p>
      <ul className={s.heroCreateAccountList}>
        {configItems.map((item, i) => {
          return (
            <li className={s.heroCreateAccountItem} key={`${item}+${i}`}>
              <div className={s.heroCreateAccountIconWrapper}>
                <CheckCircleOutlined className={s.heroCreateAccountCheckIcon} />
              </div>
              <div>{item}</div>
            </li>
          )
        })}
      </ul>
      <Button
        black
        className={s.heroCreateAccountSingUp}
        onClick={handleSignUpButton}
      >
        Sign up
      </Button>
    </div>
  )
  return (
    <V2Hero
      shorterContent={true}
      shorterMobileImage={true}
      reverse
      imageSrc={`${PROD_URL}/assets/images/landing-page/create-account.png`}
      imageAlt="create an account"
      content={content}
      contentFrameBgColor="#DABBB7"
    />
  )
}

export default HeroCreateAccount
