'use client'

import { IInputSearchProps } from './inputSearchInterface'
import styles from './inputSearch.module.css'
import { useRef } from 'react'
import { ClearCircleIcon } from '@/components/svg/clear-circle-icon/clear-circle-icon'
import { SearchIcon } from '@/components/svg/search-icon/search'

export const InputSearch = ({
  allowClear,
  className,
  onClear,
  onSearchClick,
  ...restProps
}: IInputSearchProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleClear = () => {
    if (inputRef.current) {
      inputRef.current.value = ''
      inputRef.current?.focus()
      onClear && onClear()
    }
  }

  const handleSearch = () => {
    if (inputRef.current) {
      onSearchClick && onSearchClick(inputRef.current.value)
    }
  }

  return (
    <div className={[styles.container, className || ''].join(' ')}>
      <div className={styles.inputContainer}>
        <input {...restProps} className={styles.input} ref={inputRef} />
        {allowClear ? (
          <button onClick={handleClear} className={styles.clear}>
            <ClearCircleIcon />
          </button>
        ) : null}
      </div>
      {onSearchClick ? (
        <button onClick={handleSearch} className={styles.search}>
          <SearchIcon />
        </button>
      ) : null}
    </div>
  )
}
