'use client'

import { useMemo } from 'react'
import { BusinessCategory } from '@/types/api/business-category'
import { Select } from '@/components/v2/atoms/select/select'
import { Tag } from '@/types/Tag'
import s from './business-category-select.module.css'

interface BusinessCategorySelectProps {
  bcUiPlacement: number[]
  bcValue?: number
  bcList: BusinessCategory[]
  onChangeBc: (value: number) => void
  bcTagValue?: number[]
  bcTagsList?: Tag[]
  onChangeBcTag?: (value: number[]) => void
}

export const V2BusinessCategorySelect = ({
  bcUiPlacement,
  bcValue,
  bcList,
  onChangeBc,
  bcTagValue,
  bcTagsList,
  onChangeBcTag,
}: BusinessCategorySelectProps) => {
  /**
   * Get business category data
   */
  const bcOptions = useMemo(() => {
    const hasUiPlacement = !!bcUiPlacement

    let bcOptions = bcList?.map((category) => ({
      label: category.name,
      value: category.id,
    }))

    if (hasUiPlacement) {
      bcOptions = bcOptions.filter((category) =>
        bcUiPlacement.includes(category.value)
      )
    }

    return bcOptions
  }, [bcList, bcUiPlacement])

  const bcValueLabel = useMemo(() => {
    const selectedOption = bcOptions.find((o) => o.value === bcValue)
    return selectedOption?.label
  }, [bcOptions, bcValue])

  /**
   * Get business category tags data
   */
  const bcTagOptions = useMemo(() => {
    const bcTagOptions = bcTagsList?.map((tag) => ({
      label: tag.name,
      value: tag.id,
    }))

    return bcTagOptions
  }, [bcTagsList])

  const showBcTagSelect = !!(bcValue && onChangeBcTag && bcTagOptions?.length)

  return (
    <div className={s.container}>
      <Select
        label={bcValueLabel || "I'm looking for..."}
        options={bcOptions}
        onChange={onChangeBc}
        value={bcValue}
      />
      {showBcTagSelect && (
        <Select
          label="Filters"
          options={bcTagOptions || []}
          onChange={onChangeBcTag}
          value={bcTagValue}
          multiple
        />
      )}
    </div>
  )
}
