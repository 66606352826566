import { useModal } from '@/hooks/use-modal'
import s from './articles-filters-modal.module.css'
import { FiltersIcon } from '@/components/svg/filters-icon/filters-icon'
import { Button } from '@/components/v2/atoms/button/button'
import { useScrollLock } from 'usehooks-ts'
import { XModalButton } from '@/components/v2/atoms/x-modal-button/x-modal-button'
import { ArticlesFilters } from '@/components/v2/unique/articles-page/articles-filters/articles-filters'

export const ArticlesFiltersModal = () => {
  const modal = useModal()
  useScrollLock()

  const handleClose = () => {
    modal.close()
  }

  return (
    <div className={s.container}>
      <XModalButton onClick={handleClose} className={s.xButton} />
      <div className={s.header}>
        <h3 className={s.title}>Inspiration</h3>
        <span className={s.info}>
          <FiltersIcon />
          Filters
        </span>
        <ArticlesFilters
          tagsToDisplay={modal.options.modalProps?.articlesFilters}
        />
      </div>
      {/* filters are applied on filter select */}
      <Button black onClick={handleClose}>
        Apply Filters
      </Button>
    </div>
  )
}
